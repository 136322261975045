import { performePasswordResetConfirmation } from "../../../services/RequestService";

function successAlert(onAddAlert) {
  onAddAlert("Senha alterada com sucesso", "success");
}

function noConnectionAlert(onAddAlert) {
  onAddAlert("Sem acesso ao serviço", "danger");
}

function genericError(onAddAlert) {
  onAddAlert("Erro ao alterar a senha", "danger");
}

export function passwordValidation(event, value, ffHandler) {
  if (ffHandler.getValue("newPassword")) {
    ffHandler.testFieldError("newPassword");
  }
}

export function newPasswordValidation(value, ffHandler) {
  if (value !== ffHandler.getValue("password")) {
    return "As senhas devem ser iguais.";
  }
  return null;
}

export function submitNewPassword(data, props, loading, setState) {
  if (!loading) {
    const { email, token } = props.location.state;
    setState({ buttonLoading: true }, () => {
      performePasswordResetConfirmation(
        { email, password: data.password, cpf: sessionStorage.passwordRecoveryCpf },
        token
      )
        .then((res) => {
          if (res.ok) {
            successAlert(props.onAddAlert);
            // localStorage.removeItem("token"); @todo testar
            props.history.push("/");
          } else if (res.status === 500) {
            noConnectionAlert(props.onAddAlert);
          } else {
            genericError(props.onAddAlert);
          }
        })
        .catch(() => {
          genericError(props.onAddAlert);
        })
        .finally(() => {
          setState({ buttonLoading: false });
        });
    });
  }
}
