import jwt from "jwt-decode";

export const getUser = () => {
  const jsonToken = localStorage.getItem("token");
  return jwt(jsonToken)
}

export const getView = () => {
  const {is_customer} = getUser()
  if (is_customer) return "user_view"
  return localStorage.getItem("view") || "user_view";
}
export const isCustomerView = () => getView() === "customer_view";
export const isUserView = () => getView() === "user_view";
export const setView = (isCustomer) => localStorage.setItem("view", isCustomer ? "customer_view" : "user_view");
