import {create} from "apisauce";
import {setView} from "../utils/storage";
import {Models} from "./models";
import jwt from "jwt-decode";

const {REACT_APP_API} = process.env;

export const baseURL = REACT_APP_API;

// api dev                  "http://157.245.118.217:8085";
// local                    "http://localhost:8085";
// TODO inserir nome        "http://192.168.5.185:8085";
// api produção com porta   "https://aposentadoria.qualitte.com.br:8085";
// api de prod              "https://aposentadoria.qualitte.com.br/api";

const api = create({
  baseURL,
  headers: {
    "Content-Type": "application/json",
    Authorization: localStorage.getItem("token"),
  },
});

api.addRequestTransform((request) => {
  const token = localStorage.getItem("token");
  if (token) {
    request.headers.Authorization = `${token}`;
  }
});

api.addMonitor((response) => {
  const responseToken = response?.headers?.Authorization || response?.data?.authToken
  if (responseToken) {
    localStorage.setItem("token", responseToken);
    if (localStorage.getItem("view") === null) {
      const decodedToken = jwt(responseToken);
      const is_customer = decodedToken.is_customer;
      const is_user_and_customer = decodedToken.is_user_and_customer;
      setView(is_user_and_customer ? !is_customer : is_customer);
    }
  }
});

api.addMonitor((response) => {
  if (response.config.url !== "/login" && (response.status === 401 || response.status === 403)) {
    localStorage.removeItem("token");
    window.location.href = "/";
  }
});

function isEmpty(value) {
  if (value === null || value === undefined) return true;
  if (typeof value === 'string' && value.trim() === '') return true;
  if (Array.isArray(value) && value.length === 0) return true;
  if (typeof value === 'object' && !Array.isArray(value) && Object.keys(value).length === 0) return true;
  if (typeof value === 'number' && value === 0) return true;
  return false;
}

function cache(key, minutes, func) {
  const cachedItem = localStorage.getItem(key);
  if (cachedItem) {
    const { value, expiry } = JSON.parse(cachedItem);
    if (new Date().getTime() < expiry && !isEmpty(value)) {
      return Promise.resolve(value)
    }
    localStorage.removeItem(key);
  }
  const result = func();
  result.then( resp => {
    try {
      if (resp.ok){
        const expiry = new Date().getTime() + minutes * 60 * 1000; // Tempo de expiração em milissegundos
        localStorage.setItem(key, JSON.stringify({ value: resp, expiry }));
      }
    }catch (e){
      console.warn('cannot save cache localStorage', key, e)
    }
  })
  return result;
}

async function getHash(str) {
  if (!str) return ''
  if (typeof str === 'object') str = JSON.stringify(str)
    let hash = 5381;
    for (let i = 0; i < str.length; i++) {
      hash = (hash * 33) ^ str.charCodeAt(i);
    }
    return hash >>> 0;
}

// MESSAGENS DE ACESSO
export async function performeLogin(params) {
  localStorage.clear();
  return await httpPost("/login", params);
}

export async function performePasswordResetRequest(params) {
  return await httpPostWithoutAuthorizationWithParams(
    "/access/password_reset_request",
    params
  );
}

export async function performePasswordResetCode(params) {
  return await httpPostWithoutAuthorization(
    "/access/password_reset_code",
    params
  );
}

export async function performePasswordResetConfirmation(params, token) {
  return await httpPostWithOutsideAuthorization(
    "/access/password_reset_confirmation",
    params,
    token
  );
}

// MENSAGENS DE USUÁRIO

export async function insertUser(params) {
  return await httpPost("/user/insert", params);
}

export async function updateUser(params) {
  return await httpPost("/user/update", params);
}

export async function deleteUser(params) {
  return await httpPost("/user/delete", params);
}

export async function getAllUser(params) {
  return cache('getAllUser', 60, async () => {
    return await httpGetWithParams("/user/get_all", params);
  });
}

export async function getUserBySearchBar(params) {
  return await httpPostWithParams("/user/get_by_search_bar", params);
}

export async function getUserById() {
  return await httpGet("/user/get_user_by_id");
}

export async function getUserSimpleById() {
  return await httpGet("/user/get-user-by-id");
}

export async function getEmailByCPF(params) {
  return await api.get("/user/get_email_by_cpf", params);
}

export async function hasEmailAvailable(params) {
  return await httpGetWithoutAuthorization("/user/has_email_available", params);
}

export async function hasCpfAvailable(params) {
  return await httpGetWithoutAuthorization("/user/has_cpf_available", params);
}

export async function setUserCellManager(params) {
  return await httpPostWithParams("/user/set_user_cell_manager", params);
}

export async function ativarInativarUsuario(userId, status) {
  if (status) return await httpPostWithParams("/user/disable", {userId});
  return await httpPostWithParams("/user/enable", {userId});
}

// MENSAGENS DE TERCEIRIZADOS/CREDENCIADOS

export async function getAllOutsource() {
  return cache('getAllOutsource', 60, async () => {
    return await httpGet("/outsource/get_all_outsource");
  });
}

export async function getAllAccredited() {
  return cache('getAllAccredited', 60, async () => {
    return await httpGet("/outsource/get_all_accredited");
  });
}

export async function updateOutsource(params) {
  return await httpPost("/outsource/update", params);
}

export async function deleteOutsource(params) {
  return await httpDeleteWithParams("/customer/delete", params);
}

export async function getOutsourceCnpjCpf(params) {
  return await httpGetWithParams("/outsource/get_cnpj_cpf", params);
}

export async function transformUserToCustomer(params) {
  return await httpPutWithoutAuthorization(
    "/customer/transform_user_to_customer",
    params
  );
}

// MENSAGENS DE DOCUMENTO

export async function putDocument(params) {
  return await httpPut("/document/insert", params);
}

export async function postDocument(params) {
  return await httpPost("/document/update", params);
}

export async function deleteDocument(params) {
  return await httpDeleteWithParams("/document/delete", params);
}

export async function getDocumentsByProcessId(params) {
  return await httpGetWithParams("/document/get_by_process_id", params);
}

// MENSAGENS DE INFORMAÇÕES DE USUÁRIOS
export async function insertCustomer(params) {
  return await httpPutWithoutAuthorization("/customer/insert", params);
}

export async function updateCustomer(params) {
  return await httpPost("/customer/update", params);
}

export async function getCustomerById(params) {
  return await httpGetWithParams("/customer/get_by_user_id", params);
}

export async function getComboListCustomer(search) {
  return await httpGet("/customer/combo-list?q=" + search);
}

export async function getCustomerGetAllSimple() {
  return cache('getCustomerGetAllSimple', 20, async () => {
    return await httpGet("/customer/get_all_simple");
  });
}

export async function getCustomerBySearchBar(params) {
  return await httpPostWithParams("/customer/get_by_search_bar", params);
}

export async function getCustomerByPagination(params) {
  return await httpPost("/customer/get_by_pagination", params);
}

export async function getAllProcessesDocuments(params) {
    return await httpGetWithParams("/customer/get_all_processes_documents", params);
}

//PRIVILEGES CONTROLLER
export async function getPrivilegeGetAll() {
  return cache('getPrivilegeGetAll', 60, async () => {
    return await httpGet("/privilege/get_all");
  });
}

//ROLE CONTROLLER
export async function getRoleGetAll() {
  return cache('getRoleGetAll', 60, async () => {
    return await httpGet("/role/get_all");
  });
}

export async function postRoleUpdate(params) {
  return await httpPost("/role/update", params);
}

export async function putRoleInsert(params) {
  return await httpPutWithParams("/role/insert", params);
}

export async function getRoleUsersLength(params) {
  return await httpGetWithParams("/role/get_users_length", params);
}

export async function deleteRole(params) {
  return await httpDeleteWithParams("/role/delete", params);
}

//CELL CONTROLLER
export async function getCellGetAll() {
  return cache('getCellGetAll', 60, async () => {
    return await httpGet("/cell/get_all");
  });
}

export async function getAllCells2Combobox() {
  return cache('getAllCells2Combobox', 60, async () => {
    return await httpGet("/cell/combo-list");
  });
}

export async function getCellFullHierarchy() {
  return cache('getCellFullHierarchy', 60, async () => {
    return await httpGet("/cell/full_hierarchy");
  });
}

//
export async function getCellForWorkgroup() {
  return cache('getCellForWorkgroup', 60, async () => {
    return await httpGet("/cell/get_cells_is_outsource");
  });
}

export async function getCellByUserId(params) {
  return cache('getCellByUserId' + await getHash(params), 60, async () => {
    return await httpGetWithParams("/cell/get_by_user_id", params);
  });
}

export async function getCellByOutsourceWithUsers(params) {
  return cache('getCellByOutsourceWithUsers' + await getHash(params), 60, async () => {
    return await httpGetWithParams("/cell/get_cells_by_outsource_with_users",params);
  });
}

export async function hasCellAvailable(params) {
  return await httpGetWithParams("/cell/has_cell_available", params);
}

export async function putCellInsert(params) {
  return await httpPut("/cell/insert", params);
}

export async function postCellUpdate(params) {
  return await httpPost("/cell/update", params);
}

export async function postCellUpdateSinglePrivilege(params) {
  return await httpPost("/cell/update_single_privilege", params);
}

export async function putCellInsertWorkGroup(params) {
  return await httpPut("/cell/insert_work_group", params);
}

export async function deleteAndRelocateUsers(params) {
  return await httpPostWithParams("/cell/delete_and_relocate_users", params);
}

export async function deleteAndDescendantsAndUsers(params) {
  return await httpPost("/cell/delete_and_descendants_and_users", params);
}

export async function deleteCompleteCell(params) {
  return await httpPost("/cell/delete_complete_cell", params);
}

export async function insertSinglePrivilege(params) {
  return await httpPost("/cell/insert_single_privilege", params);
}

export async function deletePrivilegeCell(params) {
  return await httpDeleteWithParams("/cell/delete_privilege_cell", params);
}

export async function getMissingPrivileges(params) {
  return await httpGetWithParams("/cell/get_missing_privileges", params);
}

export async function getAllInternCells() {
  return await httpGet("/cell/get_cells_is_intern");
}

// PROCESS CONTROLLER
export async function deleteProcess(id) {
  return await httpDelete("/process/" + id);
}

export async function searchProcessByDinamicFilter(filter) {
  return await httpPost("/process/search", filter);
}

export async function putInsertProcess(body) {
  return await httpPut("/process/insert_for_existing_customer", body);
}

export async function postInsertUpdate(params) {
  return await httpPost("/process/update", params);
}

export async function postRemoveSeemScenario(processId) {
  return await httpPost("/process/remove-seem?processId=" + processId);
}

export async function sendContractAgain(params) {
  return await httpPostWithParams("/process/send_contract_again", params);
}

export async function setTypeContractSign(params) {
  return await httpPostWithParams("/process/set-type-contract-sign", params);
}

export async function uploadSignedContract(params) {
  return await httpPost("/process-contract/signed-contract-upload", params);
}

export async function putInsertAction(params) {
  return await httpPut("/process/insert_action", params);
}

export async function getDocumentsByActionId(id) {
  return await httpGet(`/process/${id}/documents`);
}

export async function postUpdateAction(params) {
  return await httpPost("/process/update_action", params);
}

export async function putProcessInsertActionType(params) {
  return await httpPut("/process/insert_action_type", params);
}

export async function deleteActionType(params) {
  return await httpDeleteWithParams("/process/delete_action_type", params);
}

export async function postDoneAction(params) {
  return await httpPost("/process/done_action", params);
}

export async function postNewContract(params) {
  return await httpPost("/process/insert_new_contract", params);
}

export async function deleteAction(params) {
  return await httpDeleteWithParams("/process/delete_action", params);
}

export async function getAllActionType() {
  return cache('getAllActionType', 60, async () => {
    return await httpGet("/process/get_all_action_type");
  });
}

export async function getAllProcessType() {
  return cache('getAllProcessType', 60, async () => {
    return await httpGet("/process/get_all_process_type");
  });
}

export async function getProcessBySearchBar(params) {
  return await httpPost("/process/get_by_search_bar", params);
}

export async function getProcessByPagination(params) {
  return await httpPost("/process/get_by_pagination", params);
}

export async function getAllProcessStatus() {
  return cache('getAllProcessStatus', 60, async () => {
    return await httpGet("/process/get_all_process_status");
  });
}

export async function deleteProcessStatus(params) {
  return await httpDeleteWithParams("/process/delete_process_status", params);
}

export async function deleteProcessType(params) {
  return await httpDeleteWithParams("/process/delete_process_type", params);
}

export async function getAllProcess() {
  return await httpGet("/process/get_all_process");
}

export async function getAllProcessInProgress(page) {
  return await httpGet("/process/get_all_process_in_progress?page=" + page);
}

export async function getProcessById(params) {
  return await httpGetWithParams("/process/get_process_by_id", params);
}

export async function getCurrentProcessByUserId() {
  return await httpGetWithParams("/process/get_current_process_by_user_id");
}

export async function getAllActionByProcessId(params) {
  return await httpGetWithParams("/process/get_all_action_by_process_id",params);
}

export async function getAllExternalActionByProcessId(params) {
  return await httpGetWithParams("/process/get_all_external_action_by_process_id",params);
}

export async function setProcessDone(params) {
  return await httpPostWithParams("/process/set_process_done", params);
}

export async function getAllActionPending(page) {
  return await httpGet("/process/get_all_action_pending?page=" + page);
}

export async function putProcessInsertProcessType(params) {
  return await httpPut("/process/insert_process_type", params);
}

export async function postProcessUpdateProcessType(params) {
  return await httpPost("/process/update_process_type", params);
}

export async function postProcessUpdateActionType(params) {
  return await httpPost("/process/update_action_type", params);
}

export async function putProcessInsertProcessStatus(params) {
  return await httpPutWithParams("/process/insert_process_status", params);
}

export async function postProcessUpdateProcessStatus(params) {
  return await httpPost("/process/update_process_status", params);
}

export async function postPauseProcess(params) {
  return await httpPostWithParams("/process/pause_process", params);
}

export async function postResumeProcess(params) {
  return await httpPostWithParams("/process/resume_process", params);
}

export async function getAllWithDiscount(params) {
  return await httpGetWithParams("/process/get_payment_methods", params);
}

export async function postChooseDiscountParcels(params) {
  return await httpPost("/process/choose-discount-parcels", params);
}

export async function getCanChangeNegotiation(params) {
  return await httpGetWithParams("/process/can_change_negotiation", params);
}

export async function postChangeNegotiation(params) {
  return await httpPostWithParams("/process/change_negotiation", params);
}

export async function acceptProposal(params) {
  return await httpPost("/process/accept_proposal", params);
}

// ALERT CONTROLLER
export async function getAllAlertsByDone(page) {
  return await httpGet("/alert/get_all_by_done?page=" + page);
}

export async function putInsertAlert(params) {
  return await httpPut("/alert/insert_message", params);
}

export async function postDoneAlertTaskDelayDelivery(params) {
  return await httpPostWithParams("/alert/done_task_delay_delivery", params);
}

export async function postAlertDoneMessage(params) {
  return await httpPostWithParams("/alert/done_message", params);
}

export async function putInsertAlertTaskDelayDelivery(params) {
  return await httpPut("/alert/insert_task_delay_delivery", params);
}

export async function putInsertAlertProcessDelayDelivery(params) {
  return await httpPut("/alert/insert_process_delay_delivery", params);
}

export async function postAlertDoneProcessDelayDelivery(params) {
  return await httpPostWithParams("/alert/done_process_delay_delivery", params);
}

//PAYMENT MESSAGES

export async function confirmBoletoPayment(params) {
  return await httpPost("transaction-pay/upload-cnab240", params);
}

export async function putInsertPayment(params) {
  return await httpPut("/payment/insert", params);
}

export async function getAllPayment(params) {
  return await httpGetWithParams("/payment/get_all", params);
}

export async function postUpdatePayment(params) {
  return await httpPost("/payment/update", params);
}

export async function postPayPayment(params) {
  return await httpPost("/payment/pay", params);
}

export async function deletePayment(params) {
  return await httpDeleteWithParams("/payment/delete", params);
}

export async function getPaymentDates() {
  return await httpGet("/payment/get_payment_dates");
}

//PAYMENT METHOD
export async function postUpdatePaymentMethod(payload) {
  return Models.postUpdatePaymentMethod(api, payload);
}

//TRANSACTION PAY
export async function updateTransaction(params) {
  return await httpPut("/transaction-pay/update_transaction", params);
}

export async function postMagapay(params) {
  return await httpPost("/transaction-pay/pay", params);
}

export async function postAsaasPay(params) {
  return await httpPostWithParams("/transaction-pay/pix", params);
}

export async function getAsaasIsPay(params) {
  return await httpGetWithParams("/transaction-pay/is_payed", params);
}

export async function getAsaasPix(params) {
  return await httpGetWithParams("/transaction-pay/get_pix", params);
}

// NEW REPORT MESSAGES
export async function getReport1(params) {
  return await httpPost("/report/get_report_1", params);
}

export async function getReport2(params) {
  return await httpPost("/report/get_report_2", params);
}

export async function getReport3(params) {
  return await httpPost("/report/get_report_3", params);
}

export async function getReport4(params) {
  return await httpPost("/report/get_report_4", params);
}

export async function getReport5(params) {
  return await httpPost("/report/get_report_5", params);
}

export async function getReport6(params) {
  return await httpPost("/report/get_report_6", params);
}

export async function getReport7(params) {
  return await httpPost("/report/get_report_7", params);
}

export async function getReport8(params) {
  return await httpPost("/report/get_report_8", params);
}

export async function getReport9(params) {
  return await httpPost("/report/get_report_9", params);
}

export async function getReport10(params) {
  return await httpPost("/report/get_report_10", params);
}

export async function getReport11(params) {
  return await httpPost("/report/get_report_11", params);
}

export async function getReport12(params) {
  return await httpPost("/report/get_report_12", params);
}

export async function getReport13(params) {
  return await httpPost("/report/get_report_13", params);
}

export async function getReport14(params) {
  return await httpPost("/report/get_report_14", params);
}

export async function getReport15(params) {
  return await httpPost("/report/get_report_15", params);
}

export async function getReport16(params) {
  return await httpPost("/report/get_report_16", params);
}

//DOCUMENT TYPE MESSAGES
export async function getAllDocumentType() {
  return cache('getAllDocumentType', 60, async () => {
    return await httpGetWithoutParams("/document_type/get_all");
  });
}

export async function postDocumentTypeUpdate(params) {
  return await httpPost("/document_type/update", params);
}

export async function getDocumentTypeGetByName() {
  return await httpGet("/document_type/get_by_name");
}

export async function putDocumentTypeInsert(payload) {
  return await httpPut("/document_type/insert", payload);
}

export async function deleteDocumentType(params) {
  return await httpDeleteWithParams("/document_type", params);
}

// DOCUMENT SUB TYPE
export async function getAllDocumentSubTypes() {
  return await Models.getAllDocumentSubTypes(api);
}

export async function putInsertDocumentSubType(payload) {
  return await Models.putInsertDocumentSubType(api, payload);
}

export async function postUpdateDocumentSubType(payload) {
  return await Models.postUpdateDocumentSubType(api, payload);
}

export async function deleteDocumentSubType(subTypeId) {
  return await Models.deleteDocumentSubType(api, subTypeId);
}

// TASK TYPE MESSAGES

export async function getAllTaskType() {
  return cache('getAllTaskType', 60, async () => {
    return await httpGet("/task_type/get_all");
  });
}

export async function postTaskType(params) {
  return await httpPost("/task_type/update", params);
}

export async function putTaskType(params) {
  return await httpPut("/task_type/insert", params);
}

export async function deleteTasktype(params) {
  return await httpDeleteWithParams("/task_type/delete", params);
}

export async function postDoneTask(params) {
  return await httpPostWithParams("/task/done", params);
}

// RULE MESSAGES
export async function ruleGetAll() {
  return cache('ruleGetAll', 60, async () => {
    return await httpGet("/rule/get_all");
  });
}

export async function ruleValueGetAll(params) {
  return await httpGetWithParams("/rule_value/get_all", params);
}

export async function putRuleValue(params) {
  return await httpPut("/rule_value/insert", params);
}

export async function putUpdateRuleValue(params) {
  return await httpPut("/rule_value/update", params);
}

export async function deleteRuleValue(params) {
  return await httpDeleteWithParams("/rule_value/delete", params);
}

//REPORT MESSAGES
export async function putInsertReport(params) {
  return await httpPut("/report/insert", params);
}

export async function postUpdateReport(params) {
  return await httpPost("/report/update", params);
}

export async function deleteReport(params) {
  return await httpDeleteWithParams("/report/delete", params);
}

export async function getAllReports() {
  return cache('getAllReports', 60, async () => {
    return await httpGet("/report/get_all");
  });
}

// SCENARIO MESSAGES

export async function putInsertScenario(params) {
  return await httpPut("/scenario/insert", params);
}

export async function postUpdateScenario(params) {
  return await httpPost("/scenario/update", params);
}

export async function deleteScenario(params) {
  return await httpDeleteWithParams("/scenario/delete", params);
}

export async function getAllScenario() {
  return cache('getAllScenario', 60, async () => {
    return await httpGet("/scenario/get_all");
  });
}

export async function getIsScenarioAvaliable(params) {
  return await httpGetWithParams("/scenario/is_scenario_avaliable", params);
}

// CHART MESSAGES

export async function getDashboardStatus() {
  return await Models.getDashboardStatus(api);
}

// SEEM MESSAGES

export async function putInsertSeem(params) {
  return await httpPut("/seem/insert", params);
}

export async function postSelectSeemScenario(params) {
  return await httpPostWithParams("/seem/choose_scenario", params);
}

// CONFIGURAÇÕES DE COMISSÃO
export async function getDataTableUserComissionConfig(params) {
  return await httpPost("/user-comission-config", params);
}

export async function getAllUserComissionConfig() {
  return await httpGet("/user-comission-config");
}

export async function saveUserComissionConfig(params) {
  return await httpPut("/user-comission-config", params);
}

export async function getAllUsersNotCustomer() {
  return await httpGet("/user/list-not-customer");
}

export async function getDeleteUserComissionConfig(id) {
  return await api.delete("/user-comission-config/" + id);
}

// REQUISIÇÕES COMISSÂO

export async function getComissionReport(param) {
  return await httpPost("/comission/get-report", param);
}

export async function saveComissionReport(param) {
  return await httpPut("/comission", param);
}

export async function getComission(params) {
  return await httpPost("/comission", params);
}

export async function printReportComission(id) {
  return await httpGet("/comission/print/" + id, id);
}

export async function deleteComission(id) {
  return await httpDeleteWithParams("/comission/" + id);
}

// DIAMOND CARD
export async function postDiamond(params) {
  return await httpPost("/diamond", params);
}

export async function putDiamond(params, id) {
  return await httpPut("/diamond/" + id, params);
}

export async function getDiamondByCustomer(customerId) {
  return await httpGet("/diamond/" + customerId);
}

export async function deleteDiamond(id) {
  return await httpDelete("/diamond/" + id);
}

// DIAMOND CARD RECHARGE
export async function postDiamondRecharge(params) {
  return await httpPost("/diamond-recharge", params);
}

export async function putDiamondRecharge(params, id) {
  return await httpPut("diamond-recharge/" + id, params);
}

export async function getDiamondRechargeByDiamondId(diamondCardId) {
  return await httpGet("/diamond-recharge/" + diamondCardId);
}

export async function deleteDiamondRecharge(id) {
  return await httpDelete("diamond-recharge/" + id);
}

// MESSAGE CONFIG WIDE CHAT
export async function postMessageConfig(params) {
  return await httpPost("/messages-config", params);
}

export async function putMessageConfig(params, id) {
  return await httpPut("messages-config/" + id, params);
}

export async function getMessageConfigId(id) {
  return await httpGet("/messages-config/" + id);
}

export async function getMessageConfigListComboBox() {
  return await httpGet("/messages-config/get-combo-list");
}

export async function getAttendancesListComboBox() {
  return await httpGet("/messages-config/attendances");
}

export async function getChannelsListComboBox() {
  return await httpGet("/messages-config/channels");
}

export async function sendMessage(selectParams, templateId) {
  return await httpPost("/messages-config/send/" + templateId, selectParams);
}

// CONFIGURAÇÕES DE DOCUMENTO
export async function getDocumentTemplates() {
  return await httpGet("/document-generator");
}

export async function getDocumentLayoutById(id) {
  return await httpGet("/document-generator/" + id);
}

export async function saveDocumentLayout(params) {
  return await httpPost("/document-generator", params);
}

export async function genDocument(params) {
  return await httpPost("/document-generator/gen-doc", params);
}

export async function attachDocument(params) {
  return await httpPost("/document-generator/attach-doc", params);
}

export async function removeDocumentLayout(id) {
  return await httpDelete("/document-generator/" + id);
}


// REQUISIÇÕES PADRÃO

const headers = {
  "content-type": "application/json",
  Authorization: localStorage.getItem("token"),
};

async function httpGet(url) {
  return await api.get(url);
}

async function httpGetWithoutParams(url) {
  return await api.get(url, {headers: headers});
}

async function httpGetWithParams(url, params) {
  return await api.get(url, params, {headers: headers});
}

async function httpPost(url, params) {
  return await api.post(url, params, {headers: headers});
}

async function httpPostWithParams(url, params) {
  return await api.post(url, null, {params: params}, {headers: headers});
}

async function httpPut(url, params) {
  return await api.put(url, params, {headers: headers});
}

async function httpPutWithParams(url, params) {
  return await api.put(url, null, {params: params}, {headers: headers});
}

async function httpDelete(url, params) {
  return await api.delete(url, params, {headers: headers});
}

async function httpDeleteWithParams(url, params) {
  return await api.delete(url, null, {params: params}, {headers: headers});
}

// WithoutAuthorization
async function httpPostWithoutAuthorization(url, params) {
  let apiWithoutAuthorization = api;
  delete apiWithoutAuthorization.headers["Authorization"];
  return await apiWithoutAuthorization.post(url, params);
}

async function httpPostWithOutsideAuthorization(url, params, token) {
  let apiWithoutAuthorization = api;
  apiWithoutAuthorization.headers["Authorization"] = token;
  return await apiWithoutAuthorization.post(url, params);
}

async function httpPutWithoutAuthorization(url, params) {
  let apiWithoutAuthorization = api;
  delete apiWithoutAuthorization.headers["Authorization"];
  return await apiWithoutAuthorization.put(url, params);
}

async function httpPostWithoutAuthorizationWithParams(url, params) {
  let apiWithoutAuthorization = api;
  delete apiWithoutAuthorization.headers["Authorization"];
  return await apiWithoutAuthorization.post(
    url,
    null,
    {params: params},
    {headers: headers}
  );
}

async function httpGetWithoutAuthorization(url, params) {
  let apiWithoutAuthorization = api;
  delete apiWithoutAuthorization.headers["Authorization"];
  return await apiWithoutAuthorization.get(url, params);
}
