import qap from "../res/LogoQualitte.png";
import bms from "../res/LogoBMS.png";

import qapWhite from "../res/LogoQualitteWhite.png";
import bmsWhite from "../res/LogoBMSWhite.png";

import termQap from "../res/termo_consentimento_qualitte.pdf";
import termBMS from "../res/termo_consentimento_bms.pdf";

const {REACT_APP_ORIGIN} = process.env;

export function getLogo() {
  return REACT_APP_ORIGIN.includes("BMS") ? bms : qap;
}

export function getLogoWhite() {
  return REACT_APP_ORIGIN.includes("BMS") ? bmsWhite : qapWhite;
}

export function getTermoConsentimento() {
  return REACT_APP_ORIGIN.includes("BMS") ? termBMS : termQap;
}

export function getEmail() {
  return REACT_APP_ORIGIN.includes("BMS") ? "aposentadoria.falecom@bmsassociados.adv.br" : "aposentadoria.falecom@qualitte.com.br";
}

export function getPhone() {
  return REACT_APP_ORIGIN.includes("BMS") ? "0800 6422272" : "0800 4540606";
}

export function getCompanyName(short = false) {
  if (short){
    return REACT_APP_ORIGIN.includes("BMS") ? "BMS" : "Qualitte";
  }
  return REACT_APP_ORIGIN.includes("BMS") ? "BMS Aposentadorias e Revisões" : "Qualitte Aposentadorias";
}












