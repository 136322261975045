import React, {Component} from "react";
import {Button, Card, Form, Image} from "react-bootstrap";
import {Link} from "react-router-dom";
import "../login/LoginPage.css";
import {connect} from "react-redux";
import {alertActions} from "../../store/actions/AlertActions";
import {sendCode} from "./controllers/PasswordResetCodePageControllers";
import {Input} from "../../genericComponents";
import {FormFull} from "form-full";
import {getLogo} from "../../utils/CompanyOriginUtils";

class PasswordResetCode extends Component {
  constructor(props) {
    super(props);

    this.state = {
      buttonLoading: false,
    };
  }

  render() {
    const {buttonLoading} = this.state;
    const logo = getLogo()
    return (
      <div className="background-login">
        <div className="login-form w-100 h-100 d-flex">
          <div className="w-100 m-2">
            <Card className="login-form-wrapper">
              <div className="d-flex justify-content-center px-1 py-4 login-image-container">
                <Image
                  src={logo}
                  className="login-image on-load-image"
                  onLoad={(event) => {
                    event.target.style.opacity = 1;
                  }}
                />
              </div>
              <FormFull
                formRef={(ref) => (this.formRef = ref)}
                onSubmit={(data) =>
                  sendCode(
                    data,
                    this.props,
                    this.state.buttonLoading,
                    (state, callback) => this.setState(state, callback)
                  )
                }
              >
                <h4>Recuperação de conta</h4>
                <p>Insira o código que voce recebeu em seu e-mail.</p>

                <Form.Row>
                  <Input
                    name="code"
                    inputMaskProps={{
                      placeholder: "Código recebido",
                    }}
                    required="Campo obrigatório"
                  />
                </Form.Row>

                <div className="pt-3">
                  <Button
                    className="btn-block"
                    onClick={() => this.formRef.submit()}
                    variant="primary"
                    type="submit"
                    disabled={buttonLoading}
                  >
                    {!buttonLoading ? (
                      "Confirmar"
                    ) : (
                      <div className="login-icon-size">
                        <i className="fas fa-circle-notch login-fa-spin-custom"/>
                      </div>
                    )}
                  </Button>
                </div>
                <div className="d-flex justify-content-center pt-3">
                  <Link to="/">voltar</Link>
                </div>
              </FormFull>
            </Card>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onAddAlert: (text, alertType, alertPriority) =>
      dispatch(alertActions.addAlert(text, alertType, alertPriority)),
  };
};

export default connect(null, mapDispatchToProps)(PasswordResetCode);
