import React, { Component } from "react";
import { Form, Button, Image, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../login/LoginPage.css";
import { alertActions } from "../../store/actions/AlertActions";
import { connect } from "react-redux";
import { sendEmailToRequestCode } from "./controllers/PasswordResetRequestPageControllers";
import { InputCPF } from "../../genericComponents";
import { FormFull } from "form-full";
import {getLogo} from "../../utils/CompanyOriginUtils";

class PasswordResetRequest extends Component {
  constructor(props) {
    super(props);

    this.state = {
      buttonLoading: false,
    };
  }

  render() {
    const { buttonLoading } = this.state;
    const logo = getLogo()
    return (
      <div className="background-login">
        <div className="login-form w-100 h-100 d-flex">
          <div className="w-100 m-2">
            <Card className="login-form-wrapper">
              <div className="d-flex justify-content-center px-1 py-4 login-image-container">
                <Image
                  src={logo}
                  className="login-image on-load-image"
                  onLoad={(event) => {
                    event.target.style.opacity = 1;
                  }}
                />
              </div>
              <FormFull
                formRef={(ref) => (this.formRef = ref)}
                onSubmit={(data) =>
                  sendEmailToRequestCode(
                    data,
                    this.props,
                    this.state.buttonLoading,
                    (state, callback) => this.setState(state, callback)
                  )
                }
              >
                <div>
                  <h4>Recuperação de conta</h4>
                  <p>Você receberá um e-mail em alguns instantes com um código para cadastrar uma nova senha. </p>
                </div>
                <Form.Row>
                  <InputCPF name="cpf" required="Campo obrigatório" inputMaskProps={{ placeholder: `Informe seu CPF` }} />
                </Form.Row>
                <div className="pt-3">
                  <Button className="btn-block" variant="primary" onClick={() => this.formRef.submit()} disabled={buttonLoading}>
                    {buttonLoading ? (
                      <div className="login-icon-size">
                        <i className="fas fa-circle-notch login-fa-spin-custom" />
                      </div>
                    ) : (
                      "Enviar"
                    )}
                  </Button>
                </div>
                <div className="d-flex justify-content-center pt-3">
                  <Link to="/">voltar</Link>
                </div>
              </FormFull>
            </Card>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onAddAlert: (text, alertType, alertPriority) =>
      dispatch(alertActions.addAlert(text, alertType, alertPriority)),
  };
};

export default connect(null, mapDispatchToProps)(PasswordResetRequest);
