import React, {Component} from "react";
import {getCustomerBySearchBar} from "../../services/RequestService";
import "./CustomerPage.css";
import ReportPDF from "../../components/report/ReportPDF";
import ModalDeleteCustomer from "../../components/customer/ModalDeleteCustomer";
import {Modal} from "react-bootstrap";
import RegisterChoose from "../../components/registerChoose/containers/RegisterChoose";
import {Header, Loading, PageContainer, TableComponentWithPagination} from "../../genericComponents";
import {masks} from "../../utils";

class CustomerPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showRegisterChoose: false,
      filterValue: localStorage?.customerFilter || "",
      showModal: false,
      showDelete: false,
      customer: undefined,
      loading: true,
      list: [],
      totalItems: 0,
      fixedTotalItems: 0,
      totalPages: 0,
      page: parseInt(localStorage?.customerPageNumber || '0'),
    };
  }

  handleInputChange = (e) => {
    e.persist();
    this.setState((state) => ({...state, [e.target.name]: e.target.value, page: 0}));
    localStorage.customerFilter = e.target.value
  };

  handleSearch = (e) => {
    if (e.key === "Enter") this.getByFilter();
  };

  setOpenCustomerPageForEditing = (item) => {
    const {isPension, userId} = item;
    const category = isPension ? "pensao" : "aposentadoria";
    this.props.history.push(`/cliente/${category}/clientes/${userId}`);
  };

  componentDidMount = () => {
    this.setState({loading: true});
    this.getByFilter();
  };

  getByFilter = () => {
    this.setState({loading: true}, () => {
      const {filterValue, page} = this.state;
      getCustomerBySearchBar({searchItem: filterValue, page}).then(({data, ok}) => {
        if (ok)
          this.setState({
            list: data.content.map((item) => ({
              id: item.id,
              userId: item.userId,
              name: item.name,
              cpf: item.cpf,
              email: item.email,
              isPension: item.isPension,
              origin: item.origin,
            })).sort((a, b) => (b.id > a.id ? 1 : -1)),
            page: data.currentPage,
            totalItems: data.total,
            totalPages: data.totalPages,
            fixedTotalItems: data.fixedTotalItems,
            loading: false,
          });
      });
    });
  };

  pagination = (pageNumber) => {
    this.setState({page: pageNumber}, () => {
      console.log(pageNumber, this.state.page);
      localStorage.customerPageNumber = pageNumber;
      this.getByFilter();
    });
  };

  setShowModalDelete = (showDelete, customer, wasUpdated) => {
    if (showDelete) {
      let customerChose = {id: customer.id, name: customer.name};
      this.setState((state) => ({
        ...state,
        showDelete,
        customer: customerChose,
      }));
    } else {
      this.setState({showDelete, customer: customer});
      if (wasUpdated) {
        this.componentDidMount();
      }
    }
  };

  render() {
    const {
      filterValue,
      loading,
      showDelete,
      customer,
      showRegisterChoose,
      totalItems,
      totalPages,
      page,
      fixedTotalItems,
      list,
    } = this.state;

    return (
      <Loading loading={loading}>
        <Header
          active="Clientes"
          logged
          filter={{
            type: "text",
            className: "text-left",
            placeholder: "Buscar por ... ",
            name: "filterValue",
            value: filterValue,
            onChange: this.handleInputChange,
            onKeyPress: this.handleSearch,
            onClick: () => this.getByFilter(),
          }}
          buttons={[
            {
              icon: "fas fa-file-pdf",
              label: "Gerar relatório",
              onClick: () =>
                ReportPDF(
                  this.state.list,
                  [
                    "Id",
                    "Nome completo",
                    "CPF",
                    "Email",
                    "Número de protocolo",
                  ],
                  ["userId", "name", "cpf", "email", "applicationProtocol"] // TODO ver se o "applicationProtocol" será utilizado
                ),
            },
            this.props.privileges.clientes.create
              ? {
                onClick: () => this.setState({showRegisterChoose: true}),
                icon: "fas fa-plus-circle",
                label: "Adicionar",
              }
              : null,
          ]}
        />

        <PageContainer fixedHeight className="p-3">
          <TableComponentWithPagination
            loading={loading}
            countLabel="clientes"
            countLabelSingular="cliente"
            startSorted="id"
            header={[
              {label: "#", sortProperty: "id"},
              {label: "Nome", sortProperty: "name"},
              {label: "CPF", sortProperty: "cpf"},
              {label: "Email", sortProperty: "email"},
              {label: "Empresa", sortProperty: "origin"},
              this.props.privileges.clientes.delete
                ? {label: "Remover"}
                : null,
            ]}
            totalItems={totalItems}
            fixedTotalItems={fixedTotalItems}
            totalPages={totalPages}
            page={page}
            list={list}
            onItemClick={this.setOpenCustomerPageForEditing}
            forEach={(item) => [
              {label: item.id},
              {label: item.name},
              {label: item.cpf ? masks.CPF(item.cpf) : item.cpf},
              {label: item.email},
              {label: item.origin},
              this.props.privileges.clientes.delete
                ? {
                  onClick: () => this.setShowModalDelete(true, item),
                  label: (
                    <div className="text-center">
                      <i className="fas fa-trash-alt is-required"></i>
                    </div>
                  ),
                }
                : null,
            ]}
            onChangePage={this.pagination}
          />
        </PageContainer>
        {showDelete && (
          <ModalDeleteCustomer
            show={showDelete}
            onHide={(wasUpdated) =>
              this.setShowModalDelete(false, null, wasUpdated)
            }
            customer={customer}
          />
        )}

        <Modal
          show={showRegisterChoose}
          centered
          onHide={() => this.setState({showRegisterChoose: false})}
        >
          <Modal.Header closeButton>
            <Modal.Title>Tipo de Cliente</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <RegisterChoose from="clientes"/>
          </Modal.Body>
        </Modal>
      </Loading>
    );
  }
}

export default CustomerPage;
