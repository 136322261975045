import {
  getAllCells2Combobox,
  getAllTaskType,
  getAllUser,
  postTaskType,
  putTaskType,
} from "../../../../services/RequestService";

export const validateOtherOnBlur =
  (fieldToValidate) => (event, value, ffHandler) => {
    if (ffHandler.getValue(fieldToValidate)) {
      setTimeout(() => {
        ffHandler.testFieldError(fieldToValidate);
      }, 10);
    }
  };

export function validateTaskTypeValid(name, allTaskType, actualValue) {
  const task = allTaskType.find(
    (item) => item.name.toUpperCase() === name.toUpperCase()
  );
  return (Boolean(actualValue) && name === actualValue) || !task
    ? null
    : "O valor digitado já é um tipo de tarefa";
}

export function validateAlertDay(value, ffHandler) {
  const deadlineDays = ffHandler.getValue("deadlineDays");
  if (deadlineDays) {
    return Number(value) < Number(deadlineDays)
      ? null
      : "O dia de alerta não pode ser igual ou maior que o dia de entrega";
  }
  return null;
}

export function validateDeadlineDay(value, ffHandler) {
  const alertDays = ffHandler.getValue("alertDays");
  if (alertDays) {
    return Number(value) > Number(alertDays)
      ? null
      : "O dia de entrega não pode ser iguais ou menor que o dia de alerta";
  }
  return null;
}

export function submitTaskType(data, id, closeCallback) {
  const request = id === null ? putTaskType : postTaskType;
  request({
    id: id === null ? undefined : id,
    name: data.name,
    deadlineDays: data.deadlineDays,
    alertDays: data.alertDays,
    userId: data.scheduledToType === "user" ? data.scheduledToId : null,
    cellId: data.scheduledToType === "cell" ? data.scheduledToId : null,
  }).then((res) => {
    if (res.ok) {
      closeCallback(true);
    }
  });
}

export function modalTaskTypeMount(props, setState) {
  const {id, user, cell, name, alertDays, deadlineDays, privileges} = props;

  const disabledField =
    !privileges["configuracoes/andamentos"][id === undefined || id === null ? "create" : "update"];
  setState({disabledField, name, alertDays, deadlineDays});
  getAllTaskType().then((res) => {
    if (res.ok) {
      setState({allTaskType: res.data});
    }
  });
  getAllUser().then((res) => {
    if (res.ok) {
      const userExist = id !== null && Boolean(user);
      setState((state) => ({
        users: res.data,
        scheduledToType: userExist ? "user" : state.scheduledToType,
        scheduledToId: userExist ? String(user.id) : state.scheduledToId,
      }));
    }
  });
  getAllCells2Combobox().then((res) => {
    if (res.ok) {
      const cellExist = id !== null && Boolean(cell);
      setState((state) => ({
        cells: res.data.map((label, value) => ({id: value, name: label})),
        scheduledToType: cellExist ? "cell" : state.scheduledToType,
        scheduledToId: cellExist ? String(cell.id) : state.scheduledToId,
      }));
    }
  });
}
