import React, {Component} from "react";
import {Row, Col, OverlayTrigger, Tooltip} from "react-bootstrap";
import {getAllActionPending} from "../../../services/RequestService";
import ModalInsertAction from "../../process/ModalInsertAction";
import "../../../pages/calendar/CalendarPage.css";
import "./css.css";
import {FullHeightCard} from "../../../genericComponents";

class CardActionComp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      actions: [],
      actData: {},
      showModalInsertAction: false,
      page: 0,
      hasMore: true,
      loading: false,
    };
  }

  componentDidMount = () => {
    this.loadActions();
  };

  loadActions = () => {
    const {page, actions, hasMore, loading} = this.state;
    if (hasMore && !loading) {
      this.setState({loading: true});
      getAllActionPending(page)
        .then((res) => {
          const d = Array.isArray(res.data) ? res.data : [];
          this.setState({
            actions: actions.concat(d),
            page: page + 1,
            hasMore: d.length > 0,
            loading: false,
          });
        })
        .catch((error) => console.log("Erro action", error))
        .finally(() => {
          this.props.subtractLoading();
        });
    }
  };

  getDateFormated = (date) => {
    if (date === null || date === undefined) {
      return "Não possui";
    } else {
      var cdDate = new Date(date);
      return cdDate.toLocaleDateString("pt-BR");
    }
  };

  getDateTextColor = (data) => {
    var nowDate = new Date().getTime();
    let deliveryDate = new Date(data.deliveryDate).getTime();
    let alertDate = new Date(data.alertDate).getTime();

    if (data.done) {
      return "success-color";
    } else if (nowDate < alertDate) {
      return "warning-color";
    } else if (nowDate >= alertDate && nowDate < deliveryDate) {
      return "attention-color";
    } else if (nowDate >= deliveryDate) {
      return "danger-color";
    }
  };

  getBordColor = (data) => {
    var nowDate = new Date().getTime();
    let deliveryDate = new Date(data.deliveryDate).getTime();
    let alertDate = new Date(data.alertDate).getTime();

    if (data.done) {
      return "action-success-color-border";
    } else if (nowDate < alertDate) {
      return "action-warning-color-border";
    } else if (nowDate >= alertDate && nowDate < deliveryDate) {
      return "action-attention-color-border";
    } else if (nowDate >= deliveryDate) {
      return "action-danger-color-border";
    }
  };

  setShowModalInsertAction = (showModalInsertAction, actData) => {
    this.setState((state) => ({...state, showModalInsertAction, actData}));
    if (!showModalInsertAction) {
      this.setState((state) => ({...state, actData: undefined}));
      this.componentDidMount();
    }
  };

  render() {
    const {loading, actions, showModalInsertAction, actData} = this.state;
    return (
      <FullHeightCard
        handleScroll={this.loadActions}
        pageContainerChild
        xs={12}
        xl={4}
        header={
          <Row className="mx-0 mt-2 px-3 pt-3">
            <Col className="p-0 text-left"><span>Andamentos pendentes</span></Col>
            <Col className="p-0 text-right">
              <OverlayTrigger placement="top" overlay={
                <Tooltip> {actions?.length ? actions.length : 0} andamentos</Tooltip>
              }
              >
                <div className="d-inline insert-icon">
                  <i className="far fa-clone mr-1"/>
                  <span>{actions && actions.length}</span>
                </div>
              </OverlayTrigger>
            </Col>
          </Row>
        }
      >
        {actions?.length > 0 &&
          actions.map((act, i) => {
            return (
              <div key={act.id} className={"process-card p-3 m-2 rounded " + this.getBordColor(act)}
                   onClick={() => window.location = `/processo/${act.processId}/processos`}
              >
                <Row>
                  <Col sm={4} md={4} lg={4} xs={4} className="ml-1">
                    <Row><small>Cliente</small></Row>
                    <Row><h6>{act.customerName}</h6></Row>
                    <Row><small>Agendado</small></Row>
                    <Row><h6>{act.responsibleName}</h6></Row>
                    <Row><small>Tipo</small></Row>
                    <Row><h6>{act.actionName}</h6></Row>
                  </Col>

                  <Col sm={4} md={4} lg={4} xs={4} className="ml-1">
                    <Row><small>Tarefas</small></Row>
                    <Row><h6>{act.totalTasks}</h6></Row>
                    <Row><small>Documentos</small></Row>
                    <Row>
                      <i className="fas fa-paperclip mr-1"/>
                      <small>({act.documentsLength})</small>
                    </Row>
                    <Row className={this.getDateTextColor(act)}><small>Prazo de entrega</small></Row>
                    <Row className={this.getDateTextColor(act)}><h6>{this.getDateFormated(act.deliveryDate)}</h6></Row>
                  </Col>

                  <Col className="ml-1">
                    <Row><small>Descrição</small></Row>
                    <Row>
                      <span className="span-description" dangerouslySetInnerHTML={{__html: act.additionalInformation}}></span>
                    </Row>
                  </Col>
                </Row>
              </div>
            );
          })}
        <div className={"p-3 text-center"} style={{minHeight: "50px"}}>
          {actions.length <= 0 && <span>Você não possui nenhum andamento</span>}
          {loading && <span>Carregando...</span>}
        </div>

        {showModalInsertAction && (
          <ModalInsertAction
            show={showModalInsertAction}
            actionData={actData}
            onHide={() => this.setShowModalInsertAction(false)}
          />
        )}
      </FullHeightCard>
    );
  }
}

export default CardActionComp;
