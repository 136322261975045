import masks from "./masks";
import validations from "./validations";
import location from "./location";
import hooks from "./hooks";
import fn from "./functions";

export function getTransactionDescriptionStatus(status) {
    switch (status) {
        case 'RECEIVED': return "Pago"
        case 'CREATE': return "Criado"
        case 'PENDING': return "Pendente"
        case 'CANCELED': return "Cancelado"
        case 'ERROR': return "Erro"
        case 'OVERDUE': return "Atrasado"
        default: return 'Desconhecido'

    }
}

export { masks, validations, location, hooks, fn };
