import React from "react";
import {Card, Col, Form} from "react-bootstrap";
import {DatePicker, Select} from "element-react";

function RenderInput({multiple, name, label, value, disabled, colProps, options, toggleContent, filterable, ...props}) {
  return (
    <Col {...colProps}>
      <div className={`div-form-group ${disabled ? "" : "enabled"}`}>
        <Form.Group>
          <Form.Label>{label}</Form.Label>
          {options && (options || []).length > 0 ? (
            <Select disabled={disabled} name={name} value={value} multiple={multiple} filterable={filterable}
                    clearable={true} onChange={toggleContent.bind(this, name)} style={{width: '100%'}}>
              {options ? (<>
                <Select.Option label="Selecione" value=""/>
                {options.map((el, key) => (
                  <Select.Option key={key} label={el.label} value={el.value}/>))}
              </>) : null}
            </Select>) : (
            <Form.Control
              {...props}
              onChange={toggleContent.bind(this, name)}
              disabled={disabled}
              name={name}
              value={value}
              size="sm"
              as="input"
              className="text-truncate"
            ></Form.Control>)}
        </Form.Group>
      </div>
    </Col>);
}

function Filter({
                  cellId,
                  processTypeId,
                  processStatusId,
                  processActionId,
                  processScenarioId,
                  userId,
                  done,
                  reportType,
                  isChangingFilter,
                  allCells,
                  cellHierarchy,
                  allUsers,
                  allProcessType,
                  allProcessStatus,
                  allProcessActionType,
                  allProcessScenario,
                  filterList,
                  filterType,
                  customerName,
                  childCellId,
                  startDate,
                  endDate,
                  startDatePP,
                  endDatePP,
                  handleChangeFilter,
                  finished
                }) {
  const showUserName = false;
  const showProcessTypeId = [
    "Relatório de vendas", "Relatório de propostas enviadas", "Relatório de propostas aprovadas",
    "Relatório de contratos assinados", "Agenda dos Usuários", "Prazos Suspensos, Processos e Justificativas",
    "Relatório de contratos enviados", "Relatório de Status dos Processos", "Relatório de Pré Analise",
    "Relatório Aguardando Data do Protocolo"].includes(filterType)

  const showProcessStatusId = ["Relatório de Status dos Processos"].includes(filterType)

  const showUsers = [
    "Relatório de propostas enviadas", "Relatório de propostas aprovadas", "Relatório de contratos assinados",
    "Relatório de recebimentos", "Relatório de contratos enviados", "Relatório de cadastro de credenciados",
    "Agenda dos Usuários", "Relatório de Pré Analise", "Relatório Aguardando Data do Protocolo"].includes(filterType)

  const showStartDate = [
    "Relatório de vendas", "Relatório de propostas enviadas", "Relatório de propostas aprovadas",
    "Relatório de contratos enviados", "Relatório de contratos assinados", "Relatório de recebimentos",
    "Relatório de Status dos Processos", "Relatório de cadastro de credenciados", "Relatório de cadastro de usuários",
    "Relatório de Processos Fechados", "Agenda dos Usuários", "Prazos Suspensos, Processos e Justificativas",
    "Relatório de Pré Analise", "Relatório Aguardando Idade e Tempo", "Relatório Aguardando Data do Protocolo"].includes(filterType)

  const showEndDate = showStartDate
  const showStartDatePP = filterType === "Relatório de Acompanhamento do Radar Previdenciário";
  const showEndDatePP = filterType === "Relatório de Acompanhamento do Radar Previdenciário";
  const showDate = filterType === "Relatório de pagamento credenciado";
  const showActionTypeId = filterType === "Agenda dos Usuários";
  const showFinished = filterType === "Agenda dos Usuários";
  const showScenario = filterType === "Relatório de Acompanhamento do Radar Previdenciário";
  const showReportType = filterType === "Relatório Aguardando Idade e Tempo";
  const showWorkGroup = [
    "Relatório de vendas", "Relatório de propostas enviadas", "Relatório de propostas aprovadas", "Relatório de contratos assinados",
    "Relatório de recebimentos", "Relatório de pagamento credenciado", "Relatório de contratos enviados", "Relatório de Processos Fechados",
    "Relatório de cadastro de credenciados", "Relatório de Pré Analise"].includes(filterType)

  const cellHierarchyList = []
  let showChildWorkGroups = false
  // let showPreAnalise = ["Relatório de Pré Analise", "Relatório Aguardando Idade e Tempo"].includes(filterType)

  if (showWorkGroup && cellId.length > 0) {
    let total = 0
    // eslint-disable-next-line
    for (const id of cellId) {
      try {
        const childs = Object.values(cellHierarchy[cellId].children)
        for (const {id, name} of childs) {
          total++
          cellHierarchyList.push({label: name, value: id})
        }
      } catch (e) {
      }
    }
    showChildWorkGroups = total > 0
  }

  return (<Card>
    <div className="p-3">
      <div>
        <div className={"mb-2 border-top"}/>
        <Form.Row>
          <RenderInput
            toggleContent={handleChangeFilter}
            colProps={{xs: "12", sm: "12", md: "6"}}
            name="filterType"
            label="FATO GERADOR"
            value={filterType}
            disabled={!isChangingFilter}
            options={filterList ? filterList.map((item) => ({value: item, label: item})) : null}
          />
          {showStartDate && (
            <Col xs="6" sm="6" md="3">
              <div className={`div-form-group ${!isChangingFilter ? "" : "enabled"}`}>
                <Form.Group>
                  <Form.Label>
                    {filterType === "Relatório de pagamento credenciado" ? "DATA" : filterType === "Prazos Suspensos, Processos e Justificativas" ? "DATA INICIAL DA PAUSA" : "DATA INICIAL"}
                  </Form.Label>
                  <br />
                  <DatePicker
                    isDisabled={!isChangingFilter}
                    name="startDate"
                    value={startDate}
                    onChange={handleChangeFilter.bind(this, "startDate")}
                    format="dd/MM/yyyy"
                  />
                </Form.Group>
              </div>
            </Col>
          )}
          {showDate && (
            <Col xs="6" sm="6" md="3">
              <div className={`div-form-group ${!isChangingFilter ? "" : "enabled"}`}>
                <Form.Group>
                  <Form.Label>DATA</Form.Label>
                  <DatePicker
                    isDisabled={!isChangingFilter}
                    name="startDate"
                    value={startDate}
                    selectionMode="month"
                    onChange={handleChangeFilter.bind(this, "startDate")}
                    format="MM/yyyy"
                  />
                </Form.Group>
              </div>
            </Col>)}
          {showEndDate && (
            <Col xs="6" sm="6" md="3">
              <div className={`div-form-group ${!isChangingFilter ? "" : "enabled"}`}>
                <Form.Group>
                  <Form.Label>
                    {filterType === "Prazos Suspensos, Processos e Justificativas" ? "DATA FINAL DA PAUSA" : "DATA FINAL"}
                  </Form.Label>
                  <DatePicker
                    isDisabled={!isChangingFilter}
                    name="endDate"
                    value={endDate}
                    onChange={handleChangeFilter.bind(this, "endDate")}
                    format="dd/MM/yyyy"
                  />
                </Form.Group>
              </div>
            </Col>)}
          {showStartDatePP && (
            <Col xs="6" sm="6" md="3">
              <div className={`div-form-group ${!isChangingFilter ? "" : "enabled"}`}>
                <Form.Group>
                  <Form.Label>
                    {"PLANEJAMENTO ENCERRADO DEPOIS DE"}
                  </Form.Label>
                  <DatePicker
                    isDisabled={!isChangingFilter}
                    name="startDatePP"
                    value={startDatePP}
                    onChange={handleChangeFilter.bind(this, "startDatePP")}
                    format="dd/MM/yyyy"
                  />
                </Form.Group>
              </div>
            </Col>)}
          {showEndDatePP && (
            <Col xs="6" sm="6" md="3">
              <div className={`div-form-group ${!isChangingFilter ? "" : "enabled"}`}>
                <Form.Group>
                  <Form.Label>
                    {"PLANEJAMENTO ENCERRADO ANTES DE"}
                  </Form.Label>
                  <DatePicker
                    isDisabled={!isChangingFilter}
                    name="endDatePP"
                    value={endDatePP}
                    onChange={handleChangeFilter.bind(this, "endDatePP")}
                    format="dd/MM/yyyy"
                  />
                </Form.Group>
              </div>
            </Col>)}
          {showUserName && (
            <RenderInput
              toggleContent={handleChangeFilter}
              colProps={{xs: "12", sm: "6", md: "5"}}
              name="customerName"
              label="NOME DO CLIENTE"
              value={customerName}
              type="text"
              disabled={!isChangingFilter}
            />
          )}
          {showProcessTypeId && (
            <RenderInput
              toggleContent={handleChangeFilter}
              colProps={{xs: "12", md: "6"}}
              name="processTypeId"
              label="TESE"
              multiple={true}
              value={processTypeId}
              disabled={!isChangingFilter}
              options={allProcessType ? Object.entries(allProcessType)
                // .filter(val => showPreAnalise ? ([38, 39].includes(val[1])) : (![38, 39].includes(val[1])))
                .map((value) => ({
                  label: value[0], value: value[1],
                })) : null}
            />
          )}
          {showProcessStatusId && (
            <RenderInput
              toggleContent={handleChangeFilter}
              colProps={{xs: "12", md: "6"}}
              name="processStatusId"
              label="ANDAMENTO DO PROCESSO"
              multiple={true}
              filterable={true}
              value={processStatusId}
              disabled={!isChangingFilter}
              options={allProcessStatus ? Object.entries(allProcessStatus).map((value) => ({
                label: value[0], value: value[1],
              })) : null}
            />
          )}
          {showActionTypeId && (
            <RenderInput
              toggleContent={handleChangeFilter}
              colProps={{xs: "12", md: "6"}}
              name="processActionId"
              label="TIPO DE ANDAMENTO"
              multiple={true}
              filterable={true}
              value={processActionId}
              disabled={!isChangingFilter}
              options={allProcessActionType ? Object.entries(allProcessActionType).map((value) => ({
                label: value[0], value: value[1],
              })) : null}
            />
          )}
          {showWorkGroup && (
            <RenderInput
              toggleContent={handleChangeFilter}
              colProps={{xs: "12", md: "6"}}
              name="cellId"
              label="GRUPO DE TRABALHO"
              multiple={true}
              filterable={true}
              value={cellId}
              disabled={!isChangingFilter}
              options={allCells ? Object.entries(allCells).map((value) => ({
                label: value[0], value: value[1],
              })) : null}
            />
          )}
          {showChildWorkGroups && (
            <RenderInput
              toggleContent={handleChangeFilter}
              colProps={{xs: "12", md: "6"}}
              name="childCellId"
              label="CÉLULA FILHA"
              value={childCellId}
              disabled={!isChangingFilter}
              options={cellHierarchyList}
            />
          )}
          {showUsers && (
            <RenderInput
              toggleContent={handleChangeFilter}
              colProps={{xs: "12", md: "6"}}
              name="userId"
              label="USUÁRIO"
              multiple={true}
              filterable={true}
              value={userId}
              disabled={!isChangingFilter}
              options={allUsers ? allUsers.map((value) => ({
                label: value.name, value: value.id,
              })) : null}
            />
          )}
          {showScenario && (
            <RenderInput
              toggleContent={handleChangeFilter}
              colProps={{xs: "12", md: "6"}}
              name="processScenarioId"
              label="CENÁRIO"
              multiple={true}
              value={processScenarioId}
              disabled={!isChangingFilter}
              options={allProcessScenario ? Object.entries(allProcessScenario).map((value) => ({
                label: value[0], value: value[1],
              })) : null}
            />
          )}
          {(showFinished) && (
            <RenderInput
              toggleContent={handleChangeFilter}
              colProps={{xs: "12", md: "6"}}
              name="finished"
              label="Concluído "
              value={finished}
              disabled={!isChangingFilter}
              options={[{label: "Sim", value: true}, {label: "Não", value: false},]}
            />
          )}
          {(showProcessStatusId) && (
            <RenderInput
              toggleContent={handleChangeFilter}
              colProps={{xs: "12", md: "6"}}
              name="done"
              label="Aberto/Fechado "
              value={done}
              disabled={!isChangingFilter}
              options={[{label: "Fechado", value: true}, {label: "Aberto  ", value: false},]}
            />
          )}
          {(showReportType) && (
            <RenderInput
              toggleContent={handleChangeFilter}
              colProps={{xs: "12", md: "6"}}
              name="reportType"
              label="Tipo de Impressão"
              value={reportType}
              disabled={!isChangingFilter}
              options={[{label: "Dia/Mês/Ano", value: "DAY"}, {label: "Mês/Ano  ", value: "MONTH"},{label: "Ano", value: "YEAR"}]}
            />
          )}
        </Form.Row>
      </div>
    </div>
  </Card>);
}

export default Filter;
