import React from "react";
import { Row, Col, Form } from "react-bootstrap";
import { RadioGroup } from "../../../genericComponents";
import {getTermoConsentimento} from "../../../utils/CompanyOriginUtils";

function PensionRegister21({ id }) {
  const term = getTermoConsentimento()
  return id === undefined ? (
    <Row className="customer-row-margin">
      <Col>
        <Form.Group as={Col}>
          <Form.Label>
            Termo de Compromisso{" "}
            <Form.Label className="is-required">*</Form.Label>
          </Form.Label>
          <br />
          <a href={term} target="_blank" rel="noopener noreferrer">
            Visualizar Termo de Compromisso
          </a>
          <br />
          <Form.Label>
            Declaro que as informações acima prestadas são verdadeiras, e assumo
            a inteira responsabilidade pelas mesmas.
          </Form.Label>
          <br />
          <Row>
            <RadioGroup
              label="Concordo com os termos"
              name="commitmentTerm"
              options={[
                {
                  label: "Concordo",
                  value: "Concordo",
                },
                {
                  label: "Não concordo",
                  value: "Não concordo",
                },
              ]}
              required="É necessário concordar com os termos para prosseguir"
              inline
              validation={(value) =>
                value === "Concordo"
                  ? null
                  : "É necessário concordar com os termos para prosseguir"
              }
            />
          </Row>
        </Form.Group>
      </Col>
    </Row>
  ) : null;
}

export default React.memo(PensionRegister21);
