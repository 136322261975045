import React, {Component} from "react";
import "../styles/SingleProcess.css";
import {
  getAllActionByProcessId,
  getAllCells2Combobox,
  getAllExternalActionByProcessId,
  getAllProcessStatus,
  getAllProcessType,
  getCanChangeNegotiation,
  getCellGetAll,
  getCurrentProcessByUserId,
  getProcessById,
  postChangeNegotiation,
  postInsertUpdate,
  sendContractAgain,
  setProcessDone,
  setTypeContractSign,
} from "../../../../services/RequestService";
import {alertActions} from "../../../../store/actions/AlertActions";
import {connect} from "react-redux";
import {
  ModalAcceptProposal,
  ModalFinishProcess,
  ModalInsertAction,
  ModalInsertAttachmentDocument,
  ModalInsertDocument,
  ModalInsertSeem,
  ModalJustify,
  ModalPauseOrResumeProcess,
  ModalPaymentExtra,
  ModalSelectPayment,
  ModalSelectScenario,
  ModalUpdateStatus,
} from "../modals";
import {MyProcessHeader, ProcessInfos, SideMenu} from "../presentations";
import ModalPayments from "../../../../components/payments/ModalPayments";
import ModalContractSing from "../../../../components/contractSing/ModalContractSing";
import ModalPaymentOnFinal from "../../../../components/payments/ModalPaymentOnFinal";
import ActionList from "../presentations/ActionList";
import styles from "../styles/SingleProcess.module.css";
import ModalPaymentTypeOffer from "../modals/ModalPaymentTypeOffer";
import {getUser, isCustomerView, isUserView} from "../../../../utils/storage";
import {Loading} from "../../../../genericComponents";
import ModalGeradorDocumento from "../modals/ModalGeradorDocumento";

class SingleProcess extends Component {
  constructor(props) {
    super(props);

    const jwt = require("jwt-decode");
    const { is_admin } = jwt(localStorage.getItem("token"));

    this.state = {
      is_admin,
      id: undefined,
      from: undefined,
      oneProcess: undefined,
      processInfo: [],
      progressbarInfo: [],
      progressbarCurrentId: null,
      access: ["Interno", "Externo"],
      isAddAction: false,
      allCells: [],
      allActions: [],
      allProcessType: [],
      allProcessStatus: [],
      applicationProtocol: '',
      progressbar: 0,
      processStatus: undefined,
      processStatusDisabled: true,
      processStatusChangeModal: false,

      responsibleProcess: undefined,
      responsibleProcessDisabled: true,
      responsibleChangeModal: false,
      creatorCell: undefined,
      processType: undefined,
      processTypeDisabled: true,
      deadlineDisabled: true,
      deadline: undefined,

      showModalUpdateProcess: false,

      insertNewActionType: false,
      newActionType: "",

      done: undefined,
      doneProcessModal: false,

      sendContractAgainModal: false,

      cells: [],

      selectedAction: {},
      showModalInsertAction: false,
      showInfoHeader: true,
      showModalAttechmentDocument: false,
      showModalDocument: false,
      showModalPayment: false,
      showModalContractSing: false,
      showModalInsertSeem: false,
      showModalSelectScenario: false,
      showModalPaymentOnFinal: false,
      showModalSelectPayment: false,
      showModalPaymentTypeOffer: false,
      showModalGeradorDocumento: false,
      showModalPauseOrResumeProcess: false,
      modalPaymentExtraType: false,
      rmiSimulada: 0,
      isProcessWhoIsLoggedIn: false,
      showModalJustify: false,
      showModalAcceptProposal: false,
      updatingProcessLoading: false,
      canChangeNegotiation: false,
    };

    this.callbacksProps = {
      setShowModalAcceptProposal: this.setShowModalAcceptProposal,
      setShowModalDocument: this.setShowModalDocument,
      setShowModalInsertSeem: this.setShowModalInsertSeem,
      setShowModalPayment: this.setShowModalPayment,
      setShowModalContractSing: this.setShowModalContractSing,
      isPaymentOfProcessValid: this.isPaymentOfProcessValid,
      updateProcessButton: this.updateProcessButton,
      setDoneProcessModal: this.setDoneProcessModal,
      setSendContractAgainModal: this.setSendContractAgainModal,
      setShowModalPaymentTypeOffer: this.setShowModalPaymentTypeOffer,
      setShowModalGeradorDocumento: this.setShowModalGeradorDocumento,
      setShowModalSelectScenario: this.setShowModalSelectScenario,
      setShowModalPaymentOnFinal: this.setShowModalPaymentOnFinal,
      setShowModalJustify: this.setShowModalJustify,
      setShowModalPaymentExtra: this.setShowModalPaymentExtra,
      setShowModalSelectPayment: this.setShowModalSelectPayment,
      setShowModalPauseOrResumeProcess: this.setShowModalPauseOrResumeProcess,
      setShowModalAttachmentDocument: this.setShowModalAttachmentDocument,
      handleChangeProcessStatus: this.handleChangeProcessStatus,
      handleChangeDeadline: this.handleChangeDeadline,
      handleChangeProcessType: this.handleChangeProcessType,
      setShowModalInsertAction: this.setShowModalInsertAction,
      closeProcessUpdate: this.closeProcessUpdate,
      updateProcessSubmit: this.updateProcessSubmit,
      postProcessDone: this.postProcessDone,
      postSendContractAgain: this.postSendContractAgain,
      selectTypeSignContract: this.selectTypeSignContract,
      handleChangeResponsibleProcess: this.handleChangeResponsibleProcess,
      changeNegotiation: this.changeNegotiation,
      handleCustomerProtocol: this.handleCustomerProtocol,
    };
  }

  componentDidMount = (showModalPay = false) => {
    const {
      match: { params },
      decodedToken,
    } = this.props;
    this.setState({
      id: this.props.match.params.id,
      canChangeNegotiation: false,
    });

    getProcessById({ processId: this.props.match.params.id }).then((res) => {
      if (res.ok) {
        let oneProcess = res.data;
        let processId = oneProcess.id;
        let processStatus = oneProcess.processStatus === null ? "" : oneProcess.processStatus.name;
        let progressbar = processStatus === "" ? 0 : processStatus === "AGUARDANDO ACEITE DA PROPOSTA"
          ? 1 : processStatus === "AGUARDANDO ESCOLHA DO PAGAMENTO"
            ? 2 : processStatus === "AGUARDANDO ASSINATURA CONTRATO"
              ? 3 : processStatus === "AGUARDANDO PAGAMENTO"
                ? 4 : processStatus === "EM ANDAMENTO"
                  ? 5 : 5;
        let showModalAcceptProposal = processStatus === "AGUARDANDO ACEITE DA PROPOSTA" && isCustomerView();
        this.setState(
          (state) => ({
            ...state,
            oneProcess,
            id: oneProcess.id,
            from: params.from,
            done: oneProcess.done,
            processStatus: processStatus,
            progressbar: progressbar,
            responsibleProcess: oneProcess.cell === null ? "" : oneProcess.cell.name,
            creatorCell: oneProcess.creatorCell == null ? "" : oneProcess.creatorCell.name,
            processType: oneProcess.processType === null ? "" : oneProcess.processType.name.trim(),
            insertAction: {
              ...this.state.insertAction,
              processId: oneProcess.id,
            },
            showModalAcceptProposal,
          }),
          () => {
            const { oneProcess } = this.state;
            (isCustomerView() ? getAllExternalActionByProcessId : getAllActionByProcessId)({ process_id: oneProcess.id }).then(
              (res) => {
                if (res.ok) {
                  let progressbarInfo = [];
                  res.data.forEach((element) => {
                    progressbarInfo.push({
                      id: element.id,
                      name: element.actionType.name,
                      done: element.done,
                    });
                  });
                  this.setState((state) => ({
                    ...state,
                    processInfo: res.data,
                    progressbarInfo,
                  }));
                  this.getCurrentActionAndSort(progressbarInfo);
                  this.sortActions();
                }
              }
            );
          }
        );
        if (processStatus === "AGUARDANDO ACEITE DA PROPOSTA") {
          getCanChangeNegotiation({ processId }).then((res) => {
            if (res.ok) this.setState({ canChangeNegotiation: res.data });
          });
        }
        if (getUser().is_customer && oneProcess.processStatus.name === "AGUARDANDO ACEITE DA PROPOSTA") {
          this.setShowModalAcceptProposal(true)
        }
      }
    });
    getAllCells2Combobox().then((res) => {
      if (res.ok) {
        let allCells = {};
        let cells = [];
        res.data.forEach((item) => {
          allCells[item.label] = item.value;
          cells[item.label.trim()] = item.value;
        });
        this.setState((state) => ({ ...state, allCells, cells }));
      }
    });
    getAllProcessStatus().then((res) => {
      if (res.ok) {
        let allProcessStatus = {};
        res.data.forEach((item) => {
          allProcessStatus[item.name.trim()] = item.id;
        });
        this.setState((state) => ({ ...state, allProcessStatus }));
      }
    });
    getAllProcessType().then((res) => {
      if (res.ok) {
        let allProcessType = {};
        res.data.forEach((item) => {
          allProcessType[item.name.trim()] = item.id;
        });
        this.setState((state) => ({ ...state, allProcessType }));
      }
    });

    if (decodedToken.is_user_and_customer && decodedToken.is_customer) {
      getCurrentProcessByUserId({
        userId: decodedToken.userId,
      }).then((res) => {
        if (res.ok) {
          this.setState({
            isProcessWhoIsLoggedIn:
              res.data === parseInt(this.props.match.params.id),
          });
        }
      });
    }
  };

  sortActions = () => {
    let processInfo = this.state.processInfo;
    processInfo.sort((a, b) => {
      if (a.id < b.id) {
        return 1;
      }
      if (a.id > b.id) {
        return -1;
      }
      return 0;
    });

    this.setState((state) => ({ ...state, processInfo }));
  };

  handleChangeProcessStatus = (e) => {
    e.persist();
    let processStatusChangeModal = !this.state.processStatusChangeModal;
    this.setState((state) => ({
      ...state,
      processStatusChangeModal,
      processStatus: e.target.value,
    }));
  };
  handleChangeDeadline = (e) => {
    e.persist();
    this.setState((state) => ({
      ...state,
      deadline:
        e.target.value.length >= 10
          ? e.target.value.split("/").reverse().join("-")
          : null,
    }));
  };

  handleCustomerProtocol = (e) => {
    e.persist();
    const { applicationProtocol, oneProcess } = this.state
    this.setState((state) => ({
      ...state,
      applicationProtocol: e.target.value,
      oneProcess: {
        ...oneProcess, applicationProtocol: e.target.value
      }
    }));
    console.log(this.state, e.target.value, applicationProtocol)
  };

  handleChangeProcessType = (e) => {
    e.persist();
    let processTypeChangeModal = !this.state.processTypeChangeModal;
    this.setState((state) => ({
      ...state,
      processTypeChangeModal,
      processType: e.target.value,
    }));
  };

  handleChangeResponsibleProcess = (e) => {
    e.persist();
    this.setState((state) => ({
      ...state,
      responsibleProcess: e.target.value,
    }));
  };

  postProcessDone = () => {
    let processId = this.state.oneProcess.id;
    let data = {
      processId: processId,
      done: !this.state.done,
      userId: this.props.decodedToken.user_id,
    };

    if (data.processId !== "") {
      setProcessDone(data).then((res) => {
        if (res.ok) {
          getAllActionByProcessId({
            process_id: this.state.oneProcess.id,
          }).then((res1) => {
            if (res1.ok) {
              let doneProcessModal = this.state.doneProcessModal;
              this.setState((state) => ({
                ...state,
                processInfo: res1.data,
                doneProcessModal: !doneProcessModal,
                done: res.data.done,
              }));
              this.props.onAddAlert(
                "Processo finalizado com sucesso.",
                "success",
                false
              );
            }
          });
        } else {
          this.setState((state) => ({ ...state, doneProcessModal: false }));
          this.props.onAddAlert(
            "Houve um erro ao tentar finalizar o processo. Por favor novamente tentar mais tarde!",
            "danger",
            true
          );
        }
      });
    }
  };

  postSendContractAgain = () => {
    let processId = this.state.oneProcess.id;
    this.setState((state) => ({ ...state, updatingProcessLoading: true }));
    sendContractAgain({ processId: processId }).then((res) => {
      if (res.ok) {
        this.setState((state) => ({ ...state, updatingProcessLoading: false }));
        this.props.onAddAlert("Contrato reenviado com sucesso.", "success", false);
      } else {
        this.setState((state) => ({ ...state, updatingProcessLoading: false, }));
        res.data.log && this.props.onAddAlert(res.data.log.toString(), "danger", true);
      }
    });
  };

  selectTypeSignContract = (typeSignContract) => {
    let processId = this.state.oneProcess.id;
    this.setState((state) => ({ ...state, updatingProcessLoading: true }));
    setTypeContractSign({ processId: processId, typeSignContract }).then((res) => {
      if (res.ok) {
        this.setState((state) => ({ ...state, updatingProcessLoading: false }));
        this.props.onAddAlert("Tipo de assinatura escolhida com sucesso", "success", false);
      } else {
        this.setState((state) => ({ ...state, updatingProcessLoading: false, }));
        res.data.log && this.props.onAddAlert(res.data.log.toString(), "danger", true);
      }
    });
  };

  setShowModalInsertAction = (showModalInsertAction, selectedAction) => {
    this.setState((state) => ({
      ...state,
      showModalInsertAction,
      selectedAction,
    }));
    if (!showModalInsertAction) {
      getAllActionByProcessId({ process_id: this.state.oneProcess.id }).then(
        (res1) => {
          if (res1.ok) {
            this.setState((state) => ({
              ...state,
              processInfo: res1.data,
            }));
          }
        }
      );
      this.componentDidMount();
    }
  };

  setShowModalDocument = (showModalDocument) => {
    this.setState((state) => ({ ...state, showModalDocument }));
  };

  setShowModalPayment = (showModalPayment) => {
    this.setState((state) => ({ ...state, showModalPayment }));
    if (!showModalPayment) {
      const {
        match: { params },
      } = this.props;

      this.setState({
        id: this.props.match.params.id,
      });

      getProcessById({ processId: this.props.match.params.id }).then((res) => {
        if (res.ok) {
          let oneProcess = res.data;
          this.setState(
            (state) => ({
              ...state,
              oneProcess,
              id: oneProcess.id,
              from: params.from,
              done: oneProcess.done,
              processStatus:
                oneProcess.processStatus === null
                  ? ""
                  : oneProcess.processStatus.name,
              responsibleProcess:
                oneProcess.cell === null ? "" : oneProcess.cell.name,
              processType:
                oneProcess.processType === null
                  ? ""
                  : oneProcess.processType.name.trim(),
              insertAction: {
                ...this.state.insertAction,
                processId: oneProcess.id,
              },
            }),
            () => {
              const { oneProcess } = this.state;
              getAllActionByProcessId({ process_id: oneProcess.id }).then(
                (res) => {
                  if (res.ok) {
                    this.setState((state) => ({
                      ...state,
                      processInfo: res.data,
                    }));
                  }
                }
              );
            }
          );
        }
      });

      getCellGetAll().then((res) => {
        if (res.ok) {
          let allCells = {};
          res.data.forEach((item) => {
            allCells[item.name] = item.id;
          });
          this.setState((state) => ({ ...state, allCells }));
        }
      });
      getAllProcessStatus().then((res) => {
        if (res.ok) {
          let allProcessStatus = {};
          res.data.forEach((item) => {
            allProcessStatus[item.name.trim()] = item.id;
          });
          this.setState((state) => ({ ...state, allProcessStatus }));
        }
      });
      getAllProcessType().then((res) => {
        if (res.ok) {
          let allProcessType = {};
          res.data.forEach((item) => {
            allProcessType[item.name.trim()] = item.id;
          });
          this.setState((state) => ({ ...state, allProcessType }));
        }
      });
      getCellGetAll().then((res) => {
        if (res.ok) {
          let cells = [];
          for (let i = 0; i < res.data.length; i++) {
            cells[res.data[i].name.trim()] = res.data[i].id;
          }
          this.setState((state) => ({ ...state, cells: cells }));
        }
      });
    }
  };

  setShowModalAttachmentDocument = (showModalAttechmentDocument) => {
    this.setState((state) => ({ ...state, showModalAttechmentDocument }));
  };

  setShowModalContractSing = (showModalContractSing) => {
    this.setState((state) => ({ ...state, showModalContractSing }));
  };

  setShowModalJustify = (showModalJustify) => {
    this.setState((state) => ({ ...state, showModalJustify }));
  };

  setShowModalSelectPayment = (showModalSelectPayment) => {
    this.setState((state) => ({ ...state, showModalSelectPayment }));
    if (!showModalSelectPayment) {
      this.componentDidMount(true);
    }
  };

  setShowModalPaymentExtra = (showModalPaymentExtra) => {
    const { oneProcess } = this.state;
    this.setState((state) => ({
      ...state,
      showModalPaymentExtra,
      modalPaymentExtraType: oneProcess.payment === null,
      rmiSimulada:
        oneProcess.payment !== null ? oneProcess.payment.rmiSimulada : null,
    }));
    if (!showModalPaymentExtra) {
      this.componentDidMount();
    }
  };

  setShowModalPaymentOnFinal = (showModalPaymentOnFinal) => {
    this.setState((state) => ({ ...state, showModalPaymentOnFinal }));
  };

  setShowModalInsertSeem = (showModalInsertSeem) => {
    this.setState((state) => ({ ...state, showModalInsertSeem }));
    if (!showModalInsertSeem) {
      this.componentDidMount();
    }
  };

  setShowModalSelectScenario = (showModalSelectScenario) => {
    this.setState((state) => ({ ...state, showModalSelectScenario }));
    if (!showModalSelectScenario) {
      this.componentDidMount();
    }
  };

  setShowModalPaymentTypeOffer = (showModalPaymentTypeOffer) => {
    this.setState((state) => ({ ...state, showModalPaymentTypeOffer }));
    if (!showModalPaymentTypeOffer) {
      this.componentDidMount();
    }
  };

  setShowModalGeradorDocumento = (showModalGeradorDocumento) => {
    this.setState((state) => ({ ...state, showModalGeradorDocumento }));
    if (!showModalGeradorDocumento) {
      this.componentDidMount();
    }
  };

  setShowModalPauseOrResumeProcess = (showModalPauseOrResumeProcess) => {
    this.setState({ showModalPauseOrResumeProcess });
    if (!showModalPauseOrResumeProcess) {
      this.componentDidMount();
    }
  };

  setDoneProcessModal = (doneProcessModal) => {
    this.setState({ doneProcessModal });
  };

  setShowModalAcceptProposal = (showModalAcceptProposal, reload) => {
    this.setState({ showModalAcceptProposal });
    if (!showModalAcceptProposal && reload) {
      this.componentDidMount();
    }
  };

  updateProcessButton = () => {
    const {
      processStatusDisabled,
      responsibleProcessDisabled,
      processTypeDisabled,
      processStatus,
      processType,
      deadlineDisabled,
      is_admin,
    } = this.state;
    let disabled_deadline = true;
    if (is_admin) disabled_deadline = !deadlineDisabled;

    this.setState(
      (state) => ({
        ...state,
        processStatusDisabled:
          processStatus === "Pendente" ? true : !processStatusDisabled,
        responsibleProcessDisabled: !responsibleProcessDisabled,
        deadlineDisabled: disabled_deadline,
        processTypeDisabled: processType ? true : !processTypeDisabled,
      }),
      () => {
        if (
          !processStatusDisabled ||
          !responsibleProcessDisabled ||
          !processTypeDisabled
        ) {
          this.setState((state) => ({
            ...state,
            showModalUpdateProcess: true,
          }));
        }
      }
    );
  };

  updateProcessSubmit = (e) => {
    const {
      processStatus,
      processType,
      allProcessType,
      allProcessStatus,
      allCells,
      id,
      responsibleProcess,
      oneProcess,
      deadline,
      applicationProtocol
    } = this.state;

    let insertUpdateResponsibleProcess =
      responsibleProcess === undefined || responsibleProcess === null
        ? null
        : allCells[responsibleProcess];
    let insertUpdaterProcessType =
      processType === undefined || processType === null || processType === ""
        ? -1
        : allProcessType[processType];
    let insertUpdaterProcessStatus =
      processStatus === undefined || processStatus === null
        ? null
        : allProcessStatus[processStatus];
    let data;
    if (oneProcess.processType === null) {
      data = {
        id: id,
        cell: insertUpdateResponsibleProcess,
        processType: insertUpdaterProcessType,
        processStatus: insertUpdaterProcessStatus,
      };
    } else {
      data = {
        id: id,
        cell: insertUpdateResponsibleProcess,
        processType: -1,
        processStatus: insertUpdaterProcessStatus,
      };
    }
    if (deadline) {
      data.deadline = deadline;
    }
    data.applicationProtocol = applicationProtocol;
    this.setState((state) => ({ ...state, updatingProcessLoading: true }));
    postInsertUpdate(data).then((res) => {
      if (res.ok) {
        this.setState((state) => ({
          ...state,
          showModalUpdateProcess: false,
          oneProcess: { ...oneProcess, deliveryDate: res.data.deliveryDate },
          updatingProcessLoading: false,
        }));
        this.props.onAddAlert("Campos alterados com sucesso", "success", false);
        this.componentDidMount();
      } else {
        this.setState((state) => ({ ...state, updatingProcessLoading: false }));
      }
    });
  };

  closeProcessUpdate = () => {
    const { oneProcess } = this.state;
    let processStatus =
      oneProcess?.processStatus?.name === null
        ? ""
        : oneProcess.processStatus?.name;
    let responsibleProcess =
      oneProcess?.cell === null ? "" : oneProcess?.cell?.name;
    let processType =
      oneProcess?.processType === null ? "" : oneProcess?.processType?.name;

    this.setState((state) => ({
      ...state,
      processType,
      processStatus,
      responsibleProcess,
      showModalUpdateProcess: false,
    }));
  };

  getCurrentActionAndSort = (actions) => {
    let progressbarCurrentId = this.state.progressbarCurrentId;

    actions.sort((a, b) => {
      if (a.id > b.id) {
        return 1;
      }
      if (a.id < b.id) {
        return -1;
      }
      return 0;
    });

    for (let i = 0; i < actions.length; i++) {
      if (!actions[i].done) {
        progressbarCurrentId = i;
        break;
      }
    }
    this.setState((state) => ({
      ...state,
      progressbarInfo: actions,
      progressbarCurrentId,
    }));
  };

  isPaymentOfProcessValid = () => {
    const { oneProcess } = this.state;
    if (oneProcess?.payment) {
      return (
        oneProcess.payment.rmiSimulada === null ||
        (oneProcess.payment.rmiConcedida === null &&
          oneProcess.payment.complementoPositivo === null)
      );
    }
    return true;
  };

  changeNegotiation = () => {
    const { id } = this.state;
    postChangeNegotiation({ processId: id }).then((res) => {
      if (res.ok) {
        this.props.onAddAlert(
          "Revisão da proposta solicitada com sucesso",
          "success",
          false
        );
        this.componentDidMount();
      } else {
        this.props.onAddAlert(res.data.log.toString(), "danger", true);
      }
    });
  };

  getOneProcessUsedFields(oneProcess) {
    const obj = {};
    if (oneProcess) {
      obj.processContractListLength = oneProcess?.processContractList.length;
      obj.payAtTheBeginning = oneProcess?.processType?.payAtTheBeginning;
      obj.processName = oneProcess?.processType?.name;
      obj.documentsTypeLength = oneProcess?.processType?.documentsType.length;
      obj.processStatusName = oneProcess?.processStatus?.name;
      obj.hasTransactions = oneProcess?.transactions.length;
      obj.canPauseOrResume = oneProcess?.deliveryDate !== null;
      obj.isPaused = oneProcess?.paused;
      obj.hasPayment = oneProcess?.payment !== null;
      obj.hasSeem = oneProcess?.seem !== null;
      obj.isScenarioSelected = oneProcess?.seem?.isScenarioSelected;
      obj.isProcessWhoIsLoggedIn = this.state.isProcessWhoIsLoggedIn;
      obj.canChangeNegotiation = this.state.canChangeNegotiation;
    }

    return obj;
  }

  render() {
    const {
      id,
      allProcessStatus,
      oneProcess,
      showModalInsertAction,
      selectedAction,
      showModalAttechmentDocument,
      showModalDocument,
      showModalPayment,
      showModalContractSing,
      showModalJustify,
      showModalSelectPayment,
      showModalPauseOrResumeProcess,
      showModalPaymentExtra,
      showModalPaymentTypeOffer,
      showModalGeradorDocumento,
      modalPaymentExtraType,
      rmiSimulada,
      showModalPaymentOnFinal,
      showModalInsertSeem,
      showModalSelectScenario,
      showModalAcceptProposal,
      updatingProcessLoading
    } = this.state;
    const { decodedToken } = this.props;
    const menuButtonsConditions = this.getOneProcessUsedFields(oneProcess);
    const _isUserView = isUserView();
    return (
      <Loading loading={updatingProcessLoading}>
        <Loading loading={!oneProcess} hideChildrenWhileLoading>
          {oneProcess && (
            <div className="single-process-body">
              <MyProcessHeader
                decodedToken={decodedToken}
                oneProcess={oneProcess}
                responsibleProcessDisabled={
                  this.state.responsibleProcessDisabled
                }
                processTypeDisabled={this.state.processTypeDisabled}
                done={this.state.done}
                doneProcessModal={this.state.doneProcessModal}
                menuButtonsConditions={menuButtonsConditions}
                isUserView={_isUserView}
                {...this.callbacksProps}
              />
              <div className={styles.container}>
                <div xs={!this.props.decodedToken.is_customer ? "9" : "12"} className={styles.scrollContainer}>
                  {/* Dados do processo */}
                  <ProcessInfos
                    allCells={this.state.allCells}
                    allProcessType={this.state.allProcessType}
                    processType={this.state.processType}
                    processTypeDisabled={this.state.processTypeDisabled}
                    deadlineDisabled={this.state.deadlineDisabled}
                    responsibleProcessDisabled={
                      this.state.responsibleProcessDisabled
                    }
                    allProcessStatus={this.state.allProcessStatus}
                    processStatus={this.state.processStatus}
                    processStatusDisabled={this.state.processStatusDisabled}
                    oneProcess={oneProcess}
                    responsibleProcess={this.state.responsibleProcess}
                    creatorCell={this.state.creatorCell}
                    {...this.callbacksProps}
                  />
                  <br />
                  {/* Actions */}
                  <ActionList
                    processInfo={this.state.processInfo}
                    done={this.state.done}
                    processType={this.state.processType}
                    processStatus={this.state.processStatus}
                    decodedToken={this.props.decodedToken}
                    privileges={this.props.privileges}
                    {...this.callbacksProps}
                  />
                </div>
                {(this.props.decodedToken.is_user_and_customer && _isUserView ? true : !this.props.decodedToken.is_customer) && (
                  <SideMenu
                    oneProcess={oneProcess}
                    decodedToken={this.props.decodedToken}
                    done={this.state.done}
                    doneProcessModal={this.state.doneProcessModal}
                    processTypeDisabled={this.state.processTypeDisabled}
                    responsibleProcessDisabled={
                      this.state.responsibleProcessDisabled
                    }
                    menuButtonsConditions={menuButtonsConditions}
                    {...this.callbacksProps}
                  />
                )}
              </div>
            </div>
          )}
        </Loading>
        {showModalAcceptProposal && (
          <ModalAcceptProposal
            show={showModalAcceptProposal}
            proposal={(oneProcess?.processContractList || []).filter(f => f.name.includes('proposta_')).pop()}
            onHide={(reload) => this.setShowModalAcceptProposal(false, reload)}
          />
        )}
        {showModalInsertAction && id && (
          <ModalInsertAction
            process={oneProcess}
            show={showModalInsertAction}
            processId={id}
            actionData={selectedAction}
            onHide={() => this.setShowModalInsertAction(false)}
          />
        )}
        {/* Modal change process status */}
        <ModalUpdateStatus
          showModalUpdateProcess={this.state.showModalUpdateProcess}
          updatingProcessLoading={this.state.updatingProcessLoading}
          {...this.callbacksProps}
        />
        {/* Modal finish process */}
        <ModalFinishProcess
          doneProcessModal={this.state.doneProcessModal}
          {...this.callbacksProps}
        />

        {/* Modal Stop Process */}
        {showModalPauseOrResumeProcess && (
          <ModalPauseOrResumeProcess
            show={showModalPauseOrResumeProcess}
            onHide={() => this.setShowModalPauseOrResumeProcess(false)}
            processId={oneProcess.id}
            allProcessStatus={allProcessStatus}
            paused={oneProcess.paused}
          />
        )}
        {showModalAttechmentDocument && (
          <ModalInsertAttachmentDocument
            show={showModalAttechmentDocument}
            onHide={() => this.setShowModalAttachmentDocument(false)}
            processId={oneProcess.id}
            documentsType={oneProcess.processType.documentsType}
          />
        )}
        {showModalDocument && (
          <ModalInsertDocument
            show={showModalDocument}
            onHide={() => this.setShowModalDocument(false)}
            processId={oneProcess.id}
          />
        )}
        {showModalPayment && (
          <ModalPayments
            process={oneProcess}
            show={showModalPayment}
            onHide={() => this.setShowModalPayment(false)}
            paymentCustomParcels={JSON.parse(
              oneProcess.payment?.jsonParcels || "[]"
            )}
            paymentType={oneProcess.payment.paymentType}
            amount={oneProcess.payment.amount}
            parcel={oneProcess.payment.selectedParcel}
            processId={this.props.match.params.id}
            transactions={oneProcess.transactions}
            customerId={oneProcess.customer.id}
          />
        )}
        {showModalContractSing && (
          <ModalContractSing
            show={showModalContractSing}
            onHide={() => this.setShowModalContractSing(false)}
            processContractList={oneProcess.processContractList}
            processId={this.props.match.params.id}
          />
        )}
        {showModalJustify && (
          <ModalJustify
            show={showModalJustify}
            processId={this.props.match.params.id}
            onHide={() => this.setShowModalJustify(false)}
          />
        )}
        {/* {
                    shoModalProcessRules &&
                    <ModalProcessRules show={shoModalProcessRules} onHide={() => this.setShowModalProcessRules(false)}
                        processId={id}
                    />
                } */}
        {showModalSelectPayment && (
          <ModalSelectPayment
            processId={id}
            show={showModalSelectPayment}
            onHide={() => this.setShowModalSelectPayment(false)}
            amount={oneProcess.value}
            maxCreditCardParcels={oneProcess.creditCardParcels}
            useCreditCard={oneProcess.useCreditCard}
            maxTicketParcels={oneProcess.ticketParcels}
            useTicket={oneProcess.useTicket}
            usePix={oneProcess.usePix}
            pixPayed={oneProcess?.transactions[0]?.status === "RECIVED"}
          />
        )}
        {showModalPaymentExtra && (
          <ModalPaymentExtra
            id={oneProcess.payment === null ? null : oneProcess.payment.id}
            show={showModalPaymentExtra}
            onHide={() => this.setShowModalPaymentExtra(false)}
            type={modalPaymentExtraType}
            rmiSimulada={rmiSimulada}
            processId={oneProcess.id}
            onAddAlert={this.props.onAddAlert}
          />
        )}
        {showModalPaymentTypeOffer && (
          <ModalPaymentTypeOffer
            show={showModalPaymentTypeOffer}
            onHide={() => this.setShowModalPaymentTypeOffer(false)}
            processId={oneProcess.id}
            value={oneProcess.processType.value}
          />
        )}
        {showModalGeradorDocumento && (
          <ModalGeradorDocumento
            show={showModalGeradorDocumento}
            onHide={() => this.setShowModalGeradorDocumento(false)}
            process={oneProcess}
          />
        )}
        {showModalPaymentOnFinal && (
          <ModalPaymentOnFinal
            show={showModalPaymentOnFinal}
            onHide={() => this.setShowModalPaymentOnFinal(false)}
            payment={oneProcess.payment}
            processId={oneProcess.id}
            customerId={oneProcess.customer.id}
          />
        )}
        {showModalInsertSeem && (
          <ModalInsertSeem
            show={showModalInsertSeem}
            onHide={() => this.setShowModalInsertSeem(false)}
            processId={oneProcess.id}
            process={oneProcess}
            seem={oneProcess.seem}
          />
        )}
        {showModalSelectScenario && (
          <ModalSelectScenario
            show={showModalSelectScenario}
            seem={oneProcess.seem}
            onHide={() => this.setShowModalSelectScenario(false)}
          />
        )}
      </Loading>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onAddAlert: (text, alertType, alertPriority) =>
      dispatch(alertActions.addAlert(text, alertType, alertPriority)),
  };
};

export default connect(null, mapDispatchToProps)(SingleProcess);
