import React, {Component} from "react";
import {Button, Col, Form, InputGroup, Modal, Row} from "react-bootstrap";
import {connect} from "react-redux";
import {alertActions} from "../../store/actions/AlertActions";
import {getCustomerGetAllSimple, putInsertProcess,} from "../../services/RequestService";

class ModalInsertProcess extends Component {
    constructor(props) {
        super(props);

        this.state = {
            clients: {},
            client: "",
            showOptions: false,
            selectedProcessTypeId: null,
            customerOrigin: '',
            insertProcessLoading: false,
        };
    }

    processTypesToCreate = [
        "Planejamento Previdenciário",
        "Encaminhamento de Aposentadoria",
        "Encaminhamento de Aposentadoria Direto",
        "Pré Análise Aposentadoria",
        "Pré Análise Pensão por Morte",
        "Concessão de Pensão",
    ];

    componentDidMount = () => {
        getCustomerGetAllSimple().then((res) => {
            if (res.ok) {
                let clients = {};
                res.data.forEach((item) => {
                    if (!item["hasOpenProcess"]) {
                        clients[item.name + " - " + item.cpf] = item.id;
                    }
                });
                this.setState((state) => ({...state, clients}));
            }
        });
    };

    handleFieldChange = (e) => {
        e.persist();
        this.setState((state) => ({...state, [e.target.name]: e.target.value}));
    };

    submit = () => {
        const {clients, client, selectedProcessTypeId, customerOrigin} = this.state;
        this.setState((state) => ({...state, insertProcessLoading: true}));
        putInsertProcess({
            customerId: clients[client],
            processTypeId: selectedProcessTypeId,
            origin: customerOrigin || 'QUALITTE',
        }).then((res) => {
            if (res.ok) {
                this.props.onAddAlert("Processo adicionado com sucesso.", "success", false);
                this.props.onHide();
            } else {
                this.props.onAddAlert(`${res.status} - Erro ao adicionar o processo.`, "danger", false);
            }
            this.setState((state) => ({...state, insertProcessLoading: false}));
        });
    };

    handleSelectProcessType = (event) => {
        event.preventDefault();
        const eventValue = parseInt(event.target.value);
        const selectedValue = eventValue !== 0 ? eventValue : null;
        this.setState((state) => ({
            ...state,
            selectedProcessTypeId: selectedValue,
        }));
    };

    handleSelectOrigin = (event) => {
        event.preventDefault();
        const customerOrigin = event.currentTarget.value
        this.setState((state) => ({ ...state, customerOrigin }));
    };

    render() {
        const {
            clients,
            client,
            showOptions,
            selectedProcessTypeId,
            insertProcessLoading,
        } = this.state;
        const {show, onHide, processTypes} = this.props;

        return (
            <Modal show={show} onHide={onHide} size="md" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Cadastrar processo</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Row>
                        <Col>
                            {Object.keys(clients).length > 0 ? (
                                <p>Se o cliente não aparecer na lista, significa que o mesmo já tem um processo em
                                    andamento.</p>
                            ) : (
                                <p>Todos os cliente cadastrados já estão com um processo em andamento.</p>
                            )}
                        </Col>
                    </Form.Row>
                    {Object.keys(clients).length > 0 && (
                        <Form.Row>
                            <Col>
                                <Form.Label>Cliente</Form.Label>
                                <InputGroup>
                                    <Form.Control
                                        name="client"
                                        value={client}
                                        onChange={this.handleFieldChange}
                                        onFocus={() =>
                                            this.setState((state) => ({
                                                ...state,
                                                showOptions: true,
                                            }))
                                        }
                                    />
                                    <InputGroup.Append>
                                        <Button
                                            variant="outline-secondary"
                                            onClick={() =>
                                                this.setState((state) => ({
                                                    ...state,
                                                    showOptions: false,
                                                }))
                                            }
                                        >
                                            <i className="fas fa-times is-required"/>
                                        </Button>
                                    </InputGroup.Append>
                                </InputGroup>
                                {showOptions && (
                                    <div
                                        style={{
                                            width: "100%",
                                            border: "1px lightgray solid",
                                            borderRadius: "5px",
                                            padding: "2px",
                                            maxHeight: "150px",
                                            marginTop: "2px",
                                            position: "relative",
                                            overflowY: "auto",
                                        }}
                                    >
                                        {Object.keys(clients)
                                            .filter((e) =>
                                                e.toUpperCase().includes(client.toUpperCase())
                                            )
                                            .map((item) => {
                                                return (
                                                    <div
                                                        key={item}
                                                        onClick={() =>
                                                            this.setState((state) => ({
                                                                ...state,
                                                                client: item,
                                                                showOptions: false,
                                                            }))
                                                        }
                                                    >
                                                        {item}
                                                    </div>
                                                );
                                            })}
                                    </div>
                                )}
                            </Col>
                        </Form.Row>
                    )}
                    <Form.Row>
                        <Form.Label>Tipo de Processo</Form.Label>
                        <Form.Control as="select" onChange={this.handleSelectProcessType}>
                            <option value={0} key={0}></option>
                            {this.processTypesToCreate.map((processTypeName, key) => {
                                const id = processTypes.find(({name}) => name === processTypeName)?.id;
                                return (
                                    <option
                                        value={id}
                                        key={key + 1}
                                        disabled={id === undefined || id === null}
                                    >
                                        {processTypeName}
                                    </option>
                                );
                            })}
                        </Form.Control>
                    </Form.Row>
                    <Form.Row>
                        <Form.Label>Empresa</Form.Label>
                        <Form.Control as="select" onChange={this.handleSelectOrigin}>
                            <option value={"QUALITTE"}>QUALITTE</option>
                            <option value={"BMS"}>BMS</option>
                        </Form.Control>
                    </Form.Row>
                </Modal.Body>
                <Modal.Footer>
                    <Row>
                        <Col>
                            <Button
                                disabled={
                                    client === "" ||
                                    !Object.keys(clients).includes(client) ||
                                    selectedProcessTypeId === null ||
                                    selectedProcessTypeId === 0 ||
                                    insertProcessLoading
                                }
                                onClick={this.submit}
                            >
                                {insertProcessLoading ? (
                                    <div>
                                        <i className="fas fa-circle-notch login-fa-spin-custom"/>
                                    </div>
                                ) : (
                                    "Cadastrar"
                                )}
                            </Button>
                        </Col>
                    </Row>
                </Modal.Footer>
            </Modal>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onAddAlert: (text, alertType, alertPriority) =>
            dispatch(alertActions.addAlert(text, alertType, alertPriority)),
    };
};

export default connect(null, mapDispatchToProps)(ModalInsertProcess);
