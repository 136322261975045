import React, { Component } from "react";
import { Button, Col, Form, InputGroup, Modal, Row } from "react-bootstrap";
import DateFilter from "./DateFilter";
import {
  getAllCells2Combobox,
  getAllProcessStatus,
  getAllProcessType,
} from "../../services/RequestService";

class ModalReportFilter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      useCustomer: "false",
      customerName: "",
      useCell: "false",
      cellName: "",
      useProcessType: "false",
      processTypeName: "",
      useProcessStatus: "false",
      processStatusName: "",
      done: "false",
      createDateInterval: {
        start: "",
        end: "",
      },
      deliveryDateInterval: {
        start: "",
        end: "",
      },
      doneDateInterval: {
        start: "",
        end: "",
      },
      sections: {
        showCreateDate: false,
        showDeliveryDate: false,
        showDoneDate: false,
      },
      cells: {},
      processType: {},
      processStatus: {},
    };
  }

  componentDidMount = () => {
    getAllCells2Combobox().then((res) => {
      if (res.ok) {
        let cells = {};
        res.data.forEach((item) => {
          cells[item.value] = item.label;
        });
        this.setState((state) => ({ ...state, cells }));
      }
    });
    getAllProcessType().then((res) => {
      if (res.ok) {
        let processType = {};
        res.data.forEach((item) => {
          processType[item.id] = item.name;
        });
        this.setState((state) => ({ ...state, processType }));
      }
    });
    getAllProcessStatus().then((res) => {
      if (res.ok) {
        let processStatus = {};
        res.data.forEach((item) => {
          processStatus[item.id] = item.name;
        });
        this.setState((state) => ({ ...state, processStatus }));
      }
    });
  };

  handleInputChange = (e) => {
    e.persist();
    const { name, value } = e.target;
    this.setState((state) => ({ ...state, [name]: value }));
  };

  setShowSection = (name, value) => {
    const { sections } = this.state;
    this.setState((state) => ({
      ...state,
      sections: { ...sections, [name]: value },
    }));
  };

  setDateInterval = (name, type, value) => {
    this.setState((state) => ({
      ...state,
      [name]: { [type]: value },
    }));
  };

  setFilter = () => {
    const { setFilter, onHide } = this.props;
    const {
      useCustomer,
      customerName,
      useCell,
      cellName,
      useProcessType,
      processTypeName,
      useProcessStatus,
      processStatusName,
      done,
      createDateInterval,
      deliveryDateInterval,
      doneDateInterval,

      cells,
      processType,
      processStatus,
    } = this.state;

    let cellId = null;
    let processTypeId = null;
    let processStatusId = null;

    if (useCell) {
      Object.entries(cells).forEach((element) => {
        if (element.includes(cellName)) cellId = element[0];
      });
    }

    if (useProcessType) {
      Object.entries(processType).forEach((element) => {
        if (element.includes(processTypeName)) processTypeId = element[0];
      });
    }

    if (useProcessStatus) {
      Object.entries(processStatus).forEach((element) => {
        if (element.includes(processStatusName)) processStatusId = element[0];
      });
    }

    let data = {
      useCustomer: useCustomer,
      customerName: customerName,
      useCell: useCell,
      cellId,
      useProcessType: useProcessType,
      processTypeId,
      useProcessStatus: useProcessStatus,
      processStatusId,
      done: done,
      createDateInterval: createDateInterval,
      deliveryDateInterval: deliveryDateInterval,
      doneDateInterval: doneDateInterval,
    };
    setFilter(data);
    onHide();
  };

  render() {
    const {
      useCustomer,
      customerName,
      cells,
      useCell,
      cellName,
      processType,
      useProcessType,
      processTypeName,
      processStatus,
      useProcessStatus,
      processStatusName,
      done,
    } = this.state;
    const { showCreateDate, showDeliveryDate, showDoneDate } =
      this.state.sections;
    const { show, onHide } = this.props;
    return (
      <Modal show={show} onHide={onHide} size="lg" backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Filtrar</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>Nome do cliente</Form.Label>
            <InputGroup className="mb-3">
              <InputGroup.Prepend>
                <InputGroup.Checkbox
                  name="useCustomer"
                  value={!(useCustomer === "true")}
                  checked={useCustomer === "true"}
                  onChange={this.handleInputChange}
                />
              </InputGroup.Prepend>
              <Form.Control
                name="customerName"
                value={customerName}
                onChange={this.handleInputChange}
                disabled={useCustomer !== "true"}
              />
            </InputGroup>
          </Form.Group>
          <Form.Group>
            <Form.Label>Célula de usuários</Form.Label>
            <InputGroup className="mb-3">
              <InputGroup.Prepend>
                <InputGroup.Checkbox
                  name="useCell"
                  value={!(useCell === "true")}
                  checked={useCell === "true"}
                  onChange={this.handleInputChange}
                />
              </InputGroup.Prepend>
              <Form.Control
                as="select"
                name="cellName"
                value={cellName}
                onChange={this.handleInputChange}
                disabled={useCell !== "true"}
              >
                <option />
                {Object.entries(cells).map((item) => {
                  return <option key={item[0]}>{item[1]}</option>;
                })}
              </Form.Control>
            </InputGroup>
          </Form.Group>
          <Form.Group>
            <Form.Label>Tipo de processo</Form.Label>
            <InputGroup className="mb-3">
              <InputGroup.Prepend>
                <InputGroup.Checkbox
                  name="useProcessType"
                  value={!(useProcessType === "true")}
                  checked={useProcessType === "true"}
                  onChange={this.handleInputChange}
                />
              </InputGroup.Prepend>
              <Form.Control
                as="select"
                name="processTypeName"
                value={processTypeName}
                onChange={this.handleInputChange}
                disabled={useProcessType !== "true"}
              >
                <option />
                {Object.entries(processType).map((item) => {
                  return <option key={item[0]}>{item[1]}</option>;
                })}
              </Form.Control>
            </InputGroup>
          </Form.Group>
          <Form.Group>
            <Form.Label>Status do processo</Form.Label>
            <InputGroup className="mb-3">
              <InputGroup.Prepend>
                <InputGroup.Checkbox
                  name="useProcessStatus"
                  value={!(useProcessStatus === "true")}
                  checked={useProcessStatus === "true"}
                  onChange={this.handleInputChange}
                />
              </InputGroup.Prepend>
              <Form.Control
                as="select"
                name="processStatusName"
                value={processStatusName}
                onChange={this.handleInputChange}
                disabled={useProcessStatus !== "true"}
              >
                <option />
                {Object.entries(processStatus).map((item) => {
                  return <option key={item[0]}>{item[1]}</option>;
                })}
              </Form.Control>
            </InputGroup>
          </Form.Group>
          <Form.Group>
            <Form.Check
              name="done"
              checked={done === "true"}
              value={!(done === "true")}
              onChange={this.handleInputChange}
              label="Finalizado?"
            />
          </Form.Group>
          {/*DATA DE INICIO */}
          <Row>
            <Col sm="auto" md="auto" lg="auto" xs="auto">
              <b>Data de Criação</b>
            </Col>
            <Col>
              <hr />
            </Col>
            <Col sm="auto" md="auto" lg="auto" xs="auto" className="mb-2">
              <Button
                variant="link"
                onClick={() =>
                  this.setShowSection("showCreateDate", !showCreateDate)
                }
              >
                {showCreateDate ? "ocultar" : "exibir"}
              </Button>
            </Col>
          </Row>
          {showCreateDate ? (
            <DateFilter
              setDateInterval={(type, value) =>
                this.setDateInterval("createDateInterval", type, value)
              }
              radiosNumber={1}
            />
          ) : null}
          <Row>
            <Col sm="auto" md="auto" lg="auto" xs="auto">
              <b>Data de Entrega</b>
            </Col>
            <Col>
              <hr />
            </Col>
            <Col sm="auto" md="auto" lg="auto" xs="auto" className="mb-2">
              <Button
                variant="link"
                onClick={() =>
                  this.setShowSection("showDeliveryDate", !showDeliveryDate)
                }
              >
                {showDeliveryDate ? "ocultar" : "exibir"}
              </Button>
            </Col>
          </Row>
          {showDeliveryDate ? (
            <DateFilter
              setDateInterval={(type, value) =>
                this.setDateInterval("deliveryDateInterval", type, value)
              }
              radiosNumber={2}
            />
          ) : null}
          <Row>
            <Col sm="auto" md="auto" lg="auto" xs="auto">
              <b>Data de Término</b>
            </Col>
            <Col>
              <hr />
            </Col>
            <Col sm="auto" md="auto" lg="auto" xs="auto" className="mb-2">
              <Button
                variant="link"
                onClick={() =>
                  this.setShowSection("showDoneDate", !showDoneDate)
                }
              >
                {showDoneDate ? "ocultar" : "exibir"}
              </Button>
            </Col>
          </Row>
          {showDoneDate ? (
            <DateFilter
              setDateInterval={(type, value) =>
                this.setDateInterval("doneDateInterval", type, value)
              }
              radiosNumber={3}
            />
          ) : null}
        </Modal.Body>
        <Modal.Footer>
          <Row>
            <Col className="text-right">
              <Button onClick={() => this.setFilter()} variant="primary">
                Filtrar
              </Button>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default ModalReportFilter;
