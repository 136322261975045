import React, {Component} from "react";
import {Card, Image} from "react-bootstrap";
import RegisterChoose from "../../components/registerChoose/containers/RegisterChoose";
import {getLogo} from "../../utils/CompanyOriginUtils";

class RegisterChoicePage extends Component {
  render() {
    const logo = getLogo()
    return (
      <div className="background-login">
        <div className="login-form w-100 h-100 d-flex">
          <div className="w-100 m-2">
            <Card className="login-form-wrapper">
              <div className="d-flex justify-content-center p-4">
                <Image src={logo} fluid/>
              </div>
              <RegisterChoose from="login"/>
            </Card>
          </div>
        </div>
      </div>
    );
  }
}

export default RegisterChoicePage;
