import React, { Component } from "react";
import { Button, Col, Form, Modal, Row, Table } from "react-bootstrap";
import { getAllScenario, putInsertSeem, } from "../../../../services/RequestService";
import FileBase64 from "react-file-base64";
import ModalSeeSeemDocument from "./ModalSeeSeemDocument";
import { alertActions } from "../../../../store/actions/AlertActions";
import { connect } from "react-redux";
import { masks } from "../../../../utils";

class ModalInsertSeem extends Component {
  constructor(props) {
    super(props);

    const selectedScenarios = {}
    if (this.props.seem != null) {
      const seems = this.props.seem.seemScenarioList
      for (const seem of seems) {
        selectedScenarios[seem.scenario.id] = { date: seem.retirementDate.substring(0, 10), rmi: masks.money(seem.rmi, true), valid: true, selectedByCustomer: seem.selectedByCustomer }
      }
    }

    this.state = {
      allScenario: [],
      selectedScenarios,
      docs: [],
      sending: false,
      showModal: false,
      doc: null,
      filteredScenario: "",
      process: this.props.process,
    };
  }

  componentDidMount = () => {
    getAllScenario().then((res) => {
      if (res.ok) {
        this.setState((state) => ({
          ...state,
          allScenario: res.data,
          // selectedScenarios: {},
        }));
      }
    });
  };

  setShowPdf = (showModal, doc) => {
    this.setState((state) => ({ ...state, showModal, doc }));
    if (!showModal) {
      this.setState((state) => ({ ...state, showModal, doc: null }));
    }
  };

  handleChooseDoc = (e) => {
    let docs = this.state.docs;
    e.forEach((item) => {
      if (item["type"] === "application/pdf") {
        docs.push({
          document: item["base64"].split("base64,")[1],
          name: item["name"],
          type: item["type"],
          sendDocument: false,
        });
      } else {
        this.props.onAddAlert(
          "Arquivo não suportado. Arquivos com formato pdf são os unicos aceitos para o planejamento",
          "danger",
          true
        );
      }
    });
    this.setState((state) => ({ ...state, docs }));
  };

  handleInputChange = (e) => {
    e.persist();
    const { name, value } = e.target;
    this.setState((state) => ({ ...state, [name]: value }));
  };

  handleChooseDocumentToSend = (e) => {
    e.persist();
    const { name, value } = e.target;
    const { docs } = this.state;
    docs.forEach((item) => {
      item[name] = item.name === value;
    });
    this.setState((state) => ({ ...state, docs }));
  };

  handleChooseScenario = (e) => {
    const { name } = e.target;
    const { selectedScenarios, process } = this.state;
    let c = selectedScenarios;

    const isPreAnalise = [
      "Pré Análise Aposentadoria",
      "Pré Análise Pensão por Morte",
    ].includes(process.processType.name);
    if (isPreAnalise) {
      c = {};
      document.querySelectorAll("input[type='checkbox']").forEach((el) => {
        if (el.id !== e.target.id) el.checked = false;
      });
    }

    if (Object.keys(selectedScenarios).includes(name)) {
      delete c[name];
    } else {
      let date = new Date(Date.now());
      date = date.toISOString().split("T")[0].split("-");
      c[name] = {
        date: date[2] + "/" + date[1] + "/" + date[0],
        valid: undefined,
        rmi: "0,00",
      };
    }
    this.setState({ selectedScenarios: c });
  };

  handleInputScenarioDate = (id, e) => {
    const { name, value } = e.target;
    const { selectedScenarios } = this.state;
    selectedScenarios[name]["date"] = value;
    this.setState((state) => ({ ...state, selectedScenarios }));
  };

  handleInputRMIScenario = (e) => {
    const { name, value } = e.target;
    const { selectedScenarios } = this.state;
    selectedScenarios[name].rmi = value <= 0 ? "0,00" : masks.inputMoney(value);
    this.setState({ selectedScenarios });
  };

  handleIsChoosedScenarioValid = (id) => {
    const { selectedScenarios } = this.state;
    let c = selectedScenarios;
    let date = c[id]["date"].split("-");
    let d = new Date(
      parseInt(date[0]),
      parseInt(date[1]) - 1,
      parseInt(date[2])
    );
    if (Object.prototype.toString.call(d) === "[object Date]") {
      c[id]["valid"] = !isNaN(d.getTime());
    } else {
      c[id]["valid"] = false;
    }
    this.setState((state) => ({ ...state, c }));
  };

  submit = () => {
    const { docs, selectedScenarios } = this.state;
    this.setState((state) => ({ ...state, sending: true }));
    let valid = true;
    let scenarios = [];
    for (let i = 0; i < Object.keys(selectedScenarios).length; i++) {
      let scenariosKey = Object.keys(selectedScenarios)[i];
      if (!selectedScenarios[scenariosKey]["valid"]) {
        valid = false;
        this.props.onAddAlert(
          "Todas as datas dos cenários escolhidos devem ser preenchidos com uma data válida",
          "danger",
          true
        );
        this.setState((state) => ({ ...state, sending: false }));
        break;
      }
      scenarios.push({
        scenarioId: parseInt(scenariosKey),
        retirementDate: selectedScenarios[scenariosKey].date,
        rmi: masks.removeNumericMask(selectedScenarios[scenariosKey].rmi),
      });
    }
    if (valid) {
      putInsertSeem({
        scenarios,
        docList: docs,
        processId: this.props.processId,
      }).then((res) => {
        this.setState((state) => ({ ...state, sending: false }));
        if (res.ok) {
          this.props.onAddAlert(
            "Cenário(s) cadastrado(s) com sucesso!",
            "success",
            true
          );
          this.props.onHide();
        }
      });
    }
  };

  render() {
    const {
      allScenario,
      sending,
      showModal,
      doc,
      filteredScenario,
      docs,
      selectedScenarios,
    } = this.state;
    const { show, onHide, seem } = this.props;

    const selectedScenariosIds = Object.keys(selectedScenarios).map(n => parseInt(n))
    const lista = [
      ...allScenario.filter((e) => selectedScenariosIds.includes(e.id)),
      ...allScenario.filter((e) => !selectedScenariosIds.includes(e.id) && e.name.toUpperCase().includes(filteredScenario.toUpperCase())),
    ].map(l => Object.assign(l, { selectedByCustomer: selectedScenarios[l.id]?.selectedByCustomer || false }))

    return (
      <Modal show={show} onHide={onHide} size="xl" centered backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Parecer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {seem === null ? (
            <div>
              <Form.Row>
                <Form.Group as={Col}>
                  <Form.Label>Escolha um documento</Form.Label><br />
                  <FileBase64 multiple={true} onDone={this.handleChooseDoc.bind(this)} />
                </Form.Group>
              </Form.Row>
              <Table>
                <thead>
                  <tr>
                    <th>Nome</th>
                    <th>Documento</th>
                    <th>Enviar por e-mail?</th>
                  </tr>
                </thead>
                <tbody>
                  {docs.map((item) => {
                    return (
                      <tr key={item.name}>
                        <td>{item.name}</td>
                        <td> <Button variant="link" onClick={() => this.setShowPdf(true, item)} > visualizar</Button></td>
                        <td><Form.Check type="radio" name="sendDocument" value={item.name} onChange={this.handleChooseDocumentToSend} /></td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          ) : (
            <Row>
              <Col>
                <h5>Documentos</h5>
                <Table>
                  <thead>
                    <tr>
                      <th>Nome</th>
                      <th>Documento</th>
                    </tr>
                  </thead>
                  <tbody>
                    {seem["seemDocsList"].map((item) => {
                      return (
                        <tr key={item.id}>
                          <td>{item.name}</td>
                          <td> <a href={"data:" + item.type + ";base64," + item.document} download={item.name}><i className={"fas fa-download clickable "} /></a></td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Col>
            </Row>
          )}
          <Row>
            <Col>
              <h5>Cenário(s)</h5>
            </Col>
          </Row>
          {seem === null && (
            <Form.Row>
              <Form.Group as={Col}>
                <Form.Control name="filteredScenario" value={filteredScenario} onChange={this.handleInputChange} />
              </Form.Group>
            </Form.Row>
          )}
          <div style={{ height: '300px', overflow: 'auto' }}>
            <Table>
              <thead>
                <tr>
                  <th>Nome</th>
                  <th>Selecionar</th>
                  <th>Data de aposentadoria</th>
                  <th>RMI (R$)</th>
                  <th>Selecionado pelo cliente</th>
                </tr>
              </thead>
              <tbody>
                {lista.map((item) => {
                  const selected = selectedScenarios[item.id] || { date: undefined, rmi: undefined, valid: false }
                  return (
                    <tr key={item.id}>
                      <td title={item.description}>{item.name}</td>
                      <td><Form.Check name={item.id} type="checkbox" onChange={this.handleChooseScenario} value={item.id} id={item.id} checked={selectedScenarios[item.id]} disabled={seem} /></td>
                      <td>
                        {selectedScenariosIds.includes(item.id) && (
                          <Form.Row>
                            <Form.Group as={Col}>
                              <Form.Control name={item.id} type="date" value={selected.date} disabled={seem}
                                onChange={(e) => this.handleInputScenarioDate(item.id, e)}
                                onBlur={() => this.handleIsChoosedScenarioValid(item.id)}
                                className={selected.valid === undefined ? "" : selected.valid ? "is-valid" : "is-invalid"}
                              />
                              <Form.Text className="is-required">
                                {selected.valid === undefined ? "" : selected.valid ? "" : "Uma data válida deve ser informada"}
                              </Form.Text>
                            </Form.Group>
                          </Form.Row>
                        )}
                      </td>
                      <td>
                        {selectedScenariosIds.includes(item.id) && (
                          <Form.Control name={item.id} value={selected.rmi} onChange={this.handleInputRMIScenario} className={"mr-5"} disabled={seem} />
                        )}
                      </td>
                      <td>{item.selectedByCustomer ? 'Sim' : 'Não'}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </Modal.Body>
        {
          seem === null && (
            <Modal.Footer className="p-0">
              <Row className="w-100 m-0">
                <Col className=" p-0">
                  <Button variant="primary" className="w-100 button-without-rounded" onClick={this.submit} disabled={sending}>
                    {sending ? "Cadastrando ..." : "Cadastrar"}
                  </Button>
                </Col>
              </Row>
            </Modal.Footer>
          )
        }

        {
          show && doc !== null && (
            <ModalSeeSeemDocument
              doc={doc}
              show={showModal}
              onHide={() => this.setShowPdf(false, null)}
            />
          )
        }
      </Modal >
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onAddAlert: (text, alertType, alertPriority) =>
      dispatch(alertActions.addAlert(text, alertType, alertPriority)),
  };
};

export default connect(null, mapDispatchToProps)(ModalInsertSeem);
