import React from "react";
import "./App.css";
import {BrowserRouter as Router, Redirect, Route, Switch, useLocation,} from "react-router-dom";
import Login from "./pages/login/LoginPage";
import RegisterChoicePage from "./pages/login/RegisterChoicePage";
import PasswordResetRequest from "./pages/login/PasswordResetRequestPage";
import PasswordResetCode from "./pages/login/PasswordResetCodePage";
import PasswordResetConfirmation from "./pages/login/PasswordResetConfirmationPage";
import CalendarPage from "./pages/calendar/CalendarPage";
import CustomerPage from "./pages/customer/CustomerPage";
import SettingsUserPage from "./pages/settings/user/UserPage";
import SettingsPartnerPage from "./pages/settings/partner/PartnerPage";
import CellPage from "./pages/settings/cell/CellPage";
import WorkGroupPage from "./pages/settings/workGroup/WorkGroupPage";
import SettingsProcessPage from "./pages/settings/process/SettingsProcessPage";
import SettingsActionPage from "./pages/settings/action/SettingsActionPage";
import ProcessPage from "./pages/process/ProcessPage";
import ReportPage from "./pages/report/ReportPage";
import ComissionPage from "./pages/commission/ComissionPage";
import ComissionConfig from "./pages/settings/comission/ComissionConfig";
import WideChatConfig from "./pages/settings/wide-chat/WideChatConfig";
import MessageSender from "./pages/message/MessageSender";
import SingleProcess from "./pages/singleProcess/views/containers/SingleProcess";
import SingleCustomerPage from "./pages/customer/SingleCustomerPage";
import FinancialPage from "./pages/financial/FinancialPage";
import HomePage from "./pages/home/HomePage";
import Page404 from "./pages/404/Page404";
import CustomerDocumentsPage from "./pages/customer/CustomerDocumentsPage";
import NavigationComp from "./components/navigation/NavigationComp";
import jwt from "jwt-decode";
import AlertComp from "./services/alerts/AlertComp";
import GeneralPanelPage from "./pages/generalPanel/views/containers/GeneralPanelPage";
import DocumentGenerator from "./pages/settings/document-generator/DocumentGenerator";

if (process.env.REACT_APP_ORIGIN === "BMS"){
  require("./index-bms.css")
} else{
  require("./index-qualitte.css");
}

function getTokenInfos() {
  const jsonToken = localStorage.getItem("token");
  let decodedToken = null;
  let privileges = {};
  let openRoutes = true;
  if (jsonToken) {
    decodedToken = jwt(jsonToken);
    openRoutes = false;
    if (decodedToken.privileges !== "") {
      privileges = JSON.parse(decodedToken.privileges);
    }
  }
  return { decodedToken, privileges, openRoutes };
}

function useTokenAndPrivileges() {
  const location = useLocation();
  const { decodedToken, privileges, openRoutes } = getTokenInfos();
  const [state, setState] = React.useState({
    decodedToken,
    privileges,
    openRoutes,
  });

  React.useEffect(() => {
    const { decodedToken, privileges, openRoutes } = getTokenInfos();
    if (Boolean(decodedToken)) {
      if (!state.decodedToken) {
        setState({ decodedToken, privileges, openRoutes });
      } else if (state.decodedToken.privileges !== decodedToken?.privileges) {
        setState({ decodedToken, privileges, openRoutes });
      } else if (state.decodedToken.user_id !== decodedToken.user_id) {
        setState({ decodedToken, privileges, openRoutes });
      }
    } else {
      if (Boolean(state.decodedToken)) {
        setState({ decodedToken: null, privileges: null, openRoutes });
      }
    }
  }, [location, setState, state]);
  return { ...state, pathname: location.pathname };
}

function Pages() {
  const { decodedToken, privileges, openRoutes, pathname } =
    useTokenAndPrivileges();

  function renderRoute(path, Component) {
    return <Route exact path={path} component={Component} />;
  }

  function renderLoggedRoute(path, Component) {
    return renderRoute(path, (props) => (
      <Component
        privileges={privileges}
        decodedToken={decodedToken}
        {...props}
      />
    ));
  }
  const is_customer = decodedToken?.is_customer;
  const is_admin = decodedToken?.is_admin;
  const is_user_and_customer = decodedToken?.is_user_and_customer;
  const valid = (!is_user_and_customer ? !is_customer : true)
  return openRoutes ? (
    <Switch>
      {renderRoute("/", Login)}
      {renderRoute("/cadastro", RegisterChoicePage)}
      {renderRoute("/recuperar_senha_requisicao", PasswordResetRequest)}
      {renderRoute("/recuperar_senha_codigo", PasswordResetCode)}
      {renderRoute("/recuperar_senha_confirmacao", PasswordResetConfirmation)}
      {renderRoute("/cadastro/:category/:from", SingleCustomerPage)}
      {renderRoute("/404", Page404)}
      <Redirect to={"/404"} />
    </Switch>
  ) : (
    <NavigationComp decodedToken={decodedToken} privileges={privileges} selectedPath={pathname}>
      <Switch>
        {/* ADMIN AND PARTNERS ROUTES */}

        {valid && is_admin && renderLoggedRoute("/painel_geral", GeneralPanelPage)}
        {is_admin && renderLoggedRoute("/configuracoes/wide-chat", WideChatConfig)}
        {is_admin && renderLoggedRoute("/enviar-mensagens", MessageSender)}
        {is_admin && renderLoggedRoute("/configuracoes/gerador-documento", DocumentGenerator)}

        {/* PERMISSÕES: Removido até segunda ordem */}
        {(valid && privileges?.relatorios?.read) && renderLoggedRoute("/relatorio", ReportPage)}
        {(valid && privileges?.relatorios?.read) && renderLoggedRoute("/comissao", ComissionPage)}
        {(valid && privileges?.agenda?.read) && renderLoggedRoute("/agenda", CalendarPage)}
        {(valid && privileges?.processo?.read) && renderLoggedRoute("/processos", ProcessPage)}
        {(valid && privileges?.clientes?.read) && renderLoggedRoute("/clientes", CustomerPage)}
        {(valid && privileges["configuracoes/usuarios"]?.read) && renderLoggedRoute("/configuracoes/usuarios", SettingsUserPage)}
        {(valid && privileges["configuracoes/parceiros"]?.read) && renderLoggedRoute("/configuracoes/parceiros", SettingsPartnerPage)}
        {(valid && privileges?.["configuracoes/celulas"]?.read) && renderLoggedRoute("/configuracoes/celulas", CellPage)}
        {(valid && privileges?.["configuracoes/grupo_de_trabalho"]?.read) && renderLoggedRoute("/configuracoes/grupo_de_trabalho", WorkGroupPage)}
        {(valid && privileges["configuracoes/processos"]?.read) && renderLoggedRoute("/configuracoes/processos", SettingsProcessPage)}
        {(valid && privileges["configuracoes/andamentos"]?.read) && renderLoggedRoute("/configuracoes/andamentos", SettingsActionPage)}

        {is_admin && renderLoggedRoute("/configuracoes/comissao-usuario", ComissionConfig)}
        {(valid && privileges?.clientes?.read) && renderLoggedRoute("/cliente/:category/:from/:id", SingleCustomerPage)}
        {((valid && privileges?.clientes?.read) && privileges?.clientes?.create) && renderLoggedRoute("/cliente/:category/:from", SingleCustomerPage)}
        {(valid && privileges?.financeiro?.read) && renderLoggedRoute("/financeiro", FinancialPage)}

        {/* CUSTOMER ROUTES */}
        {is_customer && renderLoggedRoute("/processo/:id/documentos", CustomerDocumentsPage)}
        {is_customer && renderLoggedRoute("/meus_dados/:from/:id", SingleCustomerPage)}
        {renderLoggedRoute("/processo/:id/:from", SingleProcess)}
        {renderLoggedRoute("/", HomePage)}
        {renderRoute("/404", Page404)}
        <Redirect to={"/404"} />
      </Switch>
    </NavigationComp>
  );
}

function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Pages />
        </Switch>
      </Router>
      <AlertComp />
    </div>
  );
}

export default App;
