import "bootstrap/dist/css/bootstrap.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import configureStore from "./store/store";
import {Provider} from "react-redux";

import {transitions, positions, Provider as AlertProvider} from "react-alert";
import AlertTemplate from "./components/alert";

import 'element-theme-default';
import {i18n} from 'element-react'
import locale from 'element-react/src/locale/lang/pt-br'

i18n.use(locale);

const options = {
  position: positions.TOP_CENTER,
  timeout: 5000,
  offset: "30px",
  transition: transitions.SCALE,
};

ReactDOM.render(
  <Provider store={configureStore}>
    <AlertProvider template={AlertTemplate} {...options}>
      <App/>
    </AlertProvider>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
