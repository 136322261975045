import React, {Component} from "react";
import {Button, Card, Form, Image} from "react-bootstrap";
import {Link} from "react-router-dom";
import "./LoginPage.css";
import {connect} from "react-redux";
import {alertActions} from "../../store/actions/AlertActions";
import packageJson from "../../../package.json";
import {performLogin} from "./controllers/LoginPageControllers";
import {InputCNPJ, InputCPF, InputPassword} from "../../genericComponents";
import {FormFull} from "form-full";
import {getLogo, getTermoConsentimento} from "../../utils/CompanyOriginUtils";

function RegisterButton() {
  return (
    <div className="d-flex justify-content-center pt-3">
      <span>Não possui uma conta?</span>
      <Link className="ml-2" to="/cadastro">
        Cadastre-se agora
      </Link>
    </div>
  );
}

class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.mounted = true;
    this.state = {
      loginType: "cpf",
      buttonLoading: false,
    };
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  performLoginCallback = (state, callback) => {
    if (this.mounted) {
      this.setState(state, callback);
    }
  };

  render() {
    const {loginType, buttonLoading} = this.state;
    const logo = getLogo()
    const term = getTermoConsentimento()
    const UsernameInput = loginType === "cpf" ? InputCPF : InputCNPJ;
    const {REACT_APP_ORIGIN} = process.env;
    return (
      <div className="background-login">
        <div className="login-form w-100 h-100 d-flex">
          <div className="w-100 m-2 d-inline">
            <Card className="login-form-wrapper">
              <div className="d-flex justify-content-center px-1 py-4 login-image-container">
                <Image
                  src={logo}
                  className="login-image on-load-image"
                  onLoad={(event) => {
                    event.target.style.opacity = 1;
                  }}
                />
              </div>
              <FormFull
                formRef={(ref) => (this.formRef = ref)}
                onSubmit={(data) =>
                  performLogin(
                    data,
                    this.props,
                    this.state.buttonLoading,
                    this.performLoginCallback
                  )
                }
              >
                <Form.Row>
                  <UsernameInput
                    name="username"
                    required="Campo obrigatório"
                    inputMaskProps={{
                      placeholder: `Informe seu ${loginType === "cpf" ? "CPF" : "CNPJ"
                      }`,
                    }}
                  />
                </Form.Row>
                <Form.Row>
                  <InputPassword
                    name="password"
                    required="Campo obrigatório"
                    inputMaskProps={{placeholder: "Senha"}}
                  />
                </Form.Row>

                <div className="pt-3">
                  <Button
                    className="btn-block"
                    variant="primary"
                    onClick={() => this.formRef.submit()}
                    disabled={buttonLoading}
                  >
                    {buttonLoading ? (
                      <div>
                        <i className="fas fa-circle-notch login-fa-spin-custom"/>
                      </div>
                    ) : (
                      "Entrar"
                    )}
                  </Button>
                </div>

                <RegisterButton/>
                <div className="d-flex justify-content-center pt-3">
                  <Link to="/recuperar_senha_requisicao">
                    Esqueceu sua senha?
                  </Link>
                </div>

                <div className="d-flex justify-content-center pt-3">
                  <a href={term} target="_blank" rel="noopener noreferrer">
                    Visualizar Termo de Compromisso
                  </a>
                </div>

                <div className="text-right mt-1">
                  <p>{packageJson.version}</p>
                </div>
              </FormFull>
            </Card>
            <div className="text-center pt-4 mt4">
              {REACT_APP_ORIGIN.includes("BMS") ? (
                <>
                  <span>Berkenbrock, Moratelli & Schutz Associados / </span>
                  <span>CNPJ: 58.139.167/0001-18 / </span>
                  <span>Endereço: Rua João Pessoa, 320, Sala 418, Centro - Aracaju, SE - 49.010-130</span>
                </>
              ) : (
                <>
                  <span>Qualitte Aposentadorias Ltda / </span>
                  <span>CNPJ: 04.533.572/0001-97 / </span>
                  <span>Endereço: Rua 3300, 360 - Balneário Camboriú, SC - 88330-272</span>
                </>
              )}
            </div>
          </div>
          <br/>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onAddAlert: (text, alertType, alertPriority) =>
      dispatch(alertActions.addAlert(text, alertType, alertPriority)),
  };
};

export default connect(null, mapDispatchToProps)(LoginPage);
